import React from "react";
import "./styles.scss";
import noVideo from "../../assets/no-video.svg";
import assessmentThumb from "../../assets/assessment-thumb.svg";
import flashcardThumb from "../../assets/flashcard-thumb.svg";
import knowledgeThumb from "../../assets/knowledge-thumb.svg";

const cardMapping = {
  assessment: {
    icon: assessmentThumb,
    text: "Multiple Choice",
  },
  knowledge: {
    icon: knowledgeThumb,
    text: "Info Card",
  },
  flashcard: {
    icon: flashcardThumb,
    text: "Flashcard",
  },
};

const ThumbnailComponent = ({ video, thumbnail = null }) => {
  /*
    Thumbnail component alligns with Figma design (May 15)
    Note: current impolementation doesn't have heart symbol, sybject text and blue bar 
    There is another Card component that duplicates some of this component functionality 
    and thus should be deleted and replayced with this component
    */
  return (
    <div className="video-image-container">
      {video.card_type ? (
        <div className="card-thumbnail">
          <img src={cardMapping[video.card_type]?.icon} />
          <span>{cardMapping[video.card_type]?.text}</span>
        </div>
      ) : (
        <img
          className="video-image"
          src={thumbnail ? thumbnail : video.thumbnail_url || noVideo}
          style={{
            objectFit:
              video.video_type === "landscape" ? "cover" : "scale-down",
          }}
        />
      )}
    </div>
  );
};

export default ThumbnailComponent;
