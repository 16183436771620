import React, { useEffect, useRef } from "react";
import "./styles.scss";

const Popup = ({
  key,
  className,
  children,
  closePopup,
  closeFunc,
  withContainer = false,
}) => {
  /*
    Popup animation.
  */

  const modalRef = useRef(null);

  const handleClosePopup = () => {
    if (withContainer) {
      modalRef.current.classList.add("animate__close-popup-container");
      modalRef.current
        .getElementsByClassName("popup")[0]
        ?.classList?.add("animate__close-popup");
    } else {
      modalRef.current.classList.add("animate__close-popup");
    }
    setTimeout(() => {
      closeFunc();
    }, 200);
  };

  useEffect(() => {
    if (closePopup) {
      handleClosePopup();
    }
  }, [closePopup]);

  useEffect(() => {
    const closePopupOnEsc = (e) => {
      if (e.key === "Escape") {
        handleClosePopup();
      }
    };

    window.addEventListener("keydown", closePopupOnEsc);
    return () => {
      window.removeEventListener("keydown", closePopupOnEsc);
    };
  }, []);

  return (
    <div
      key={key}
      className={`${className} popup${withContainer ? "-container" : ""}`}
      ref={modalRef}
    >
      {children}
    </div>
  );
};

export default Popup;
