import { SET_VIDEO_SPEED } from "../actions/types";
import { SET_AUDIO_LEVEL } from "../actions/types";
import { SET_MUTE } from "../actions/types";
import { SET_AUTOPLAY } from "../actions/types";

const initialState = {
  videoSpeed: 1,
  audioLevel: 1,
  isMuted: false,
  isAutoplay: true,
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_SPEED:
      return {
        ...state,
        videoSpeed: action.payload,
      };
    case SET_AUDIO_LEVEL:
      return {
        ...state,
        audioLevel: action.payload,
      };
    case SET_MUTE:
      return {
        ...state,
        isMuted: action.payload,
      };
    case SET_AUTOPLAY:
      return {
        ...state,
        isAutoplay: action.payload,
      };
    default:
      return state;
  }
};

export default playerReducer;
