import React, { useState, useEffect } from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
  hideModal,
  showModal,
  notifyError,
  notifySuccess,
} from "../../actions/global.action";
import { videoService } from "../../services/video.service.ts";
import Popup from "../../containers/Popup/Popup.js";
import ButtonComponent from "../ButtonComponent/ButtonComponent.js";

const ReportOverlay = ({ videoId, setShowReportOverlay }) => {
  /*
  pop up form with the options of reporting a video (part of MoreDropdown component)
  not included on Figma board
  todo: change icons to the ones from Figma
  */

  const [report, setReport] = useState(null);
  const [closePopup, setClosePopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showModal());
    return () => {
      dispatch(hideModal());
    };
  }, [dispatch]);

  const onReport = async () => {
    try {
      videoService.flagVideo(videoId, report)
      dispatch(notifySuccess("Video reported"));
    } catch (err) {
      console.error(err)
      dispatch(notifyError("Report failed"));
    }
    setClosePopup(true);
  };

  const options = [
    "Violent or repulsive content",
    "Hateful or abusive content",
    "Misinformation",
    "Caption issue",
    "Legal issue",
    "Spam or misleading",
    "None of these are my issue",
  ];
  return (
    <Popup
      className="report-overlay"
      closePopup={closePopup}
      closeFunc={() => setShowReportOverlay(false)}
      withContainer={true}
    >
      <div className="report-dialog popup">
        <div className="dialog-header">
          <h5>Report video</h5>
          <button onClick={() => setClosePopup(true)}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <ul className="report-options">
          {options.map((option, index) => (
            <li key={index}>
              <input
                type="radio"
                id={option}
                name="report"
                value={option}
                onChange={(e) => setReport(e.target.value)}
              />
              <label htmlFor={option}>{option}</label>
            </li>
          ))}
        </ul>
        <div className="report-buttons">
          <ButtonComponent
            text="Cancel"
            level="secondary"
            icon={false}
            handleClick={() => setClosePopup(true)}
          />
          <ButtonComponent
            text="Report"
            icon={false}
            handleClick={() => onReport()}
            level={!report ? "disable" : "primary"}
          />
        </div>
      </div>
    </Popup>
  );
};

export default ReportOverlay;
