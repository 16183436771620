import { playlistService } from "../services/playlist.service.ts";
import { userService } from "../services/user.service.ts";
import {
  FETCH_PLAYLISTS_REQUEST,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILURE,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILURE,
  SET_CURRENT_PLAYLIST,
  SET_PLAYLISTS,
  SET_WATCHING_PLAYLIST,
  UPDATE_PLAYLIST_NAME,
  UPDATE_PLAYLIST_VISIBILITY,
} from "./types";

const fetchPlaylistsRequest = () => {
  return {
    type: FETCH_PLAYLISTS_REQUEST,
  };
};

const fetchPlaylistsSuccess = (playlists) => {
  return {
    type: FETCH_PLAYLISTS_SUCCESS,
    payload: playlists,
  };
};

const fetchPlaylistsFailure = (error) => {
  return {
    type: FETCH_PLAYLISTS_FAILURE,
    payload: error,
  };
};

export const fetchPlaylists = (playlistTitle) => {
  return (dispatch) => {
    dispatch(fetchPlaylistsRequest());
    userService
      .getUserPlaylists()
      .then((response) => {
        dispatch(fetchPlaylistsSuccess(response));
        if (playlistTitle) {
          // const newPlaylist = response.data[0].find(i=>i.title === playlistTitle)
          // dispatch(setCurrentPlaylist(newPlaylist))
        } else {
          // dispatch(setCurrentPlaylist(response.data[1]))
        }
      })
      .catch((error) => {
        dispatch(fetchPlaylistsFailure(error.message));
      });
  };
};

export const createPlaylistSuccess = (playlist) => {
  return {
    type: CREATE_PLAYLIST_SUCCESS,
    payload: playlist,
  };
};

export const createPlaylistFailure = (error) => {
  return {
    type: CREATE_PLAYLIST_FAILURE,
    payload: error,
  };
};

export const createPlaylist = (
  playlistTitle,
  visibility,
  description,
  videos,
  ai_generated = false,
) => {
  return (dispatch) => {
    playlistService
      .createPlaylist(
        playlistTitle,
        visibility,
        description,
        videos,
        ai_generated,
      )
      .then((response) => {
        dispatch(createPlaylistSuccess(response));
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        dispatch(createPlaylistFailure(error.message));
      });
  };
};

export const setCurrentPlaylist = (playlist) => ({
  type: SET_CURRENT_PLAYLIST,
  payload: playlist,
});

export const setPlaylists = (playlists) => ({
  type: SET_PLAYLISTS,
  payload: playlists,
});

export const setWatchingPlaylist = (watching) => ({
  type: SET_WATCHING_PLAYLIST,
  payload: watching,
});

export const updatePlaylistName = (name) => ({
  type: UPDATE_PLAYLIST_NAME,
  payload: name,
});

export const updatePlaylistVisibility = (visibility) => ({
  type: UPDATE_PLAYLIST_VISIBILITY,
  payload: visibility,
});

export const updatePlaylistStructure = (items, currentPlaylist) => {
  return (dispatch) => {
    playlistService
      .updatePlaylistStructure(
        items,
        currentPlaylist && (currentPlaylist["playlist_item_id"] || 0),
      )
      .then(() => {
        dispatch(fetchPlaylists());
      })
      .catch(() => {
        // TODO handle error and modify the action
      });
  };
};
