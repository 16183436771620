import { AxiosResponse } from "axios";
import authService from "../services/auth.service.ts";

export function ResponseInterceptorResolve(response: AxiosResponse) {
  // console.log(response)
  return response.data;
}
const arrayPathname = ["/login", "/signup"];
export function ResponseInterceptorReject(error: any) {
  const pathname = window.location.pathname;
  if (arrayPathname.indexOf(pathname) === -1) {
    if (error.response?.data?.detail?.toLowerCase()?.includes("token")) {
      authService.logout();
      window.location.reload();
    }
  }
  if (error.response && error.response.data?.message) {
    return Promise.reject(error.response.data.message);
  }
  return Promise.reject(error);
}
