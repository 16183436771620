import React from "react";
import "./styles.scss";

const ColorComponent = ({ color, chosen = false, setChosen = () => {} }) => {
  return (
    <div
      className={`circle-component`}
      style={{
        backgroundColor: chosen ? color : "white",
      }}
      onClick={() => setChosen(!chosen)}
    >
      <div
        className={`circle-border`}
        style={{ backgroundColor: chosen ? "white" : "#e7e7e9" }}
      >
        <div
          className={`central-circle`}
          style={{ backgroundColor: color }}
        ></div>
      </div>
    </div>
  );
};

export default ColorComponent;
