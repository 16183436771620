import React from "react";
import "./styles.scss";
import {
  // faChevronLeft,
  // faEllipsisH,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown";
import { useState } from "react";

const ShareModule = ({
  typeToShare,
  idToShare,
  // setShowShare,
  setShowDropdown,
  isRightAlign,
}) => {
  /*
  part of ShareDropdown component: defines the social networks in which the video/playlist can be shared
  todo: icons should be changed to the ones from Figma
  todo: "other" option should be either eliminated or lead to another dropdown with more social media options
  */

  const dispatch = useDispatch();
  const [closeDropdown, setCloseDropdown] = useState(false);
  const textToCopy = `${window.location.origin}/${typeToShare}/${idToShare}/`;

  const handleShare = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        dispatch(notifySuccess("URL copied to clipboard!"));
      })
      .catch((err) => {
        dispatch(notifyError(`Error copying to clipboard. ${err}`));
      });
  };

  return (
    <AnimatedDropdown
      className={`share-module${isRightAlign ? " right-align" : ""}`}
      closeDropdown={closeDropdown}
      closeFunc={() => setShowDropdown(false)}
    >
      <div className="module-header">
        {/* <button onClick={() => setShowShare(false)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button> */}
        <span>Share</span>
        <button onClick={() => setCloseDropdown(true)}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>

      {/* The uncommented code is for the social media sharing that is currently removed, but 
      can be added back in the future */}
      {/* <ul className="share-options">
        <li className="share-option" onClick={() => setCloseDropdown(true)}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#1877F2" />
            <path
              d="M22.2281 20.625L22.9375 16H18.5V13C18.5 11.7344 19.1188 10.5 21.1063 10.5H23.125V6.5625C23.125 6.5625 21.2938 6.25 19.5438 6.25C15.8875 6.25 13.5 8.46563 13.5 12.475V16H9.4375V20.625H13.5V31.8062C14.3156 31.9344 15.15 32 16 32C16.85 32 17.6844 31.9344 18.5 31.8062V20.625H22.2281Z"
              fill="white"
            />
          </svg>
          <p>Facebook</p>
        </li>
        <li className="share-option" onClick={() => setCloseDropdown(true)}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M31.36 16.3637C31.36 15.2291 31.2582 14.1382 31.0691 13.0909H16V19.28H24.6109C24.24 21.28 23.1127 22.9746 21.4182 24.1091V28.1237H26.5891C29.6145 25.3382 31.36 21.2364 31.36 16.3637Z"
                fill="#4285F4"
              />
              <path
                d="M16 32C20.32 32 23.9418 30.5673 26.5891 28.1236L21.4181 24.1091C19.9854 25.0691 18.1527 25.6363 16 25.6363C11.8327 25.6363 8.30542 22.8218 7.04724 19.04H1.70178V23.1854C4.33451 28.4145 9.74542 32 16 32Z"
                fill="#34A853"
              />
              <path
                d="M7.04727 19.04C6.72727 18.08 6.54545 17.0546 6.54545 16C6.54545 14.9455 6.72727 13.92 7.04727 12.96V8.81458H1.70182C0.618182 10.9746 0 13.4182 0 16C0 18.5818 0.618182 21.0255 1.70182 23.1855L7.04727 19.04Z"
                fill="#FBBC05"
              />
              <path
                d="M16 6.36364C18.3491 6.36364 20.4581 7.17091 22.1163 8.75636L26.7054 4.16727C23.9345 1.58545 20.3127 0 16 0C9.74542 0 4.33451 3.58545 1.70178 8.81455L7.04724 12.96C8.30542 9.17818 11.8327 6.36364 16 6.36364Z"
                fill="#EA4335"
              />
            </g>
            <defs>
              <clipPath id="clip0_533_223">
                <rect width="32" height="32" rx="6" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>Google</p>
        </li>
        <li className="share-option" onClick={() => setCloseDropdown(true)}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <rect width="32" height="32" rx="6" fill="white" />
              <path
                d="M16.0648 25.6306C10.749 25.6306 8.3203 22.9226 8.3203 20.9365C8.31958 20.4626 8.51404 20.0094 8.85793 19.6835C9.20182 19.3575 9.66478 19.1876 10.1379 19.2137C12.4138 19.2137 11.8185 22.6276 16.0648 22.6276C18.2353 22.6276 19.5103 21.321 19.5103 20.0935C19.5103 19.3559 19.0888 18.513 17.6558 18.1758L12.9143 16.9799C9.10528 16.0105 8.44147 13.8927 8.44147 11.9276C8.44147 7.84987 12.1715 6.37473 15.7223 6.37473C18.994 6.37473 22.882 8.18177 22.882 10.6263C22.882 11.68 22.0022 12.2437 20.9801 12.2437C19.0361 12.2437 19.3628 9.51466 15.4536 9.51466C13.5096 9.51466 12.4876 10.4261 12.4876 11.701C12.4876 12.976 13.9996 13.408 15.3272 13.6977L18.8254 14.488C22.6608 15.352 23.6828 17.6016 23.6828 19.7563C23.6828 23.0701 21.1171 25.6306 16.0595 25.6306H16.0648ZM30.7424 18.6184C30.8943 17.7486 30.9701 16.8672 30.9689 15.9842C30.9875 11.5488 29.0387 7.3335 25.6477 4.47446C22.2567 1.61543 17.7727 0.40698 13.4043 1.17486C12.0616 0.401803 10.5387 -0.00344065 8.98938 2.20055e-05C5.80919 0.0194491 2.87782 1.72386 1.28773 4.47806C-0.302354 7.23226 -0.31264 10.6231 1.26071 13.3869C0.382791 18.2108 1.92064 23.159 5.3782 26.6355C8.83576 30.1119 13.7755 31.6767 18.6041 30.8251C19.9452 31.5975 21.4661 32.0028 23.0137 32C26.1923 31.9795 29.1219 30.2758 30.7116 27.5233C32.3014 24.7708 32.3131 21.3818 30.7424 18.6184Z"
                fill="#0078D7"
              />
            </g>
            <defs>
              <clipPath id="clip0_533_221">
                <rect width="32" height="32" rx="6" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>Skype</p>
        </li>
        <li className="share-option" onClick={() => setCloseDropdown(true)}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M29.6378 0H2.36222C1.73572 0 1.13488 0.248877 0.691879 0.691879C0.248877 1.13488 0 1.73572 0 2.36222V29.6378C0 30.2643 0.248877 30.8651 0.691879 31.3081C1.13488 31.7511 1.73572 32 2.36222 32H29.6378C30.2643 32 30.8651 31.7511 31.3081 31.3081C31.7511 30.8651 32 30.2643 32 29.6378V2.36222C32 1.73572 31.7511 1.13488 31.3081 0.691879C30.8651 0.248877 30.2643 0 29.6378 0ZM9.53778 27.26H4.72667V11.9778H9.53778V27.26ZM7.12889 9.86C6.58315 9.85693 6.05055 9.69226 5.59831 9.38678C5.14607 9.08131 4.79445 8.64871 4.58784 8.14359C4.38122 7.63846 4.32886 7.08346 4.43737 6.54861C4.54589 6.01376 4.8104 5.52304 5.19754 5.13838C5.58468 4.75372 6.07709 4.49236 6.61262 4.38728C7.14816 4.28221 7.70282 4.33812 8.2066 4.54798C8.71039 4.75784 9.14072 5.11222 9.44329 5.56642C9.74585 6.02061 9.9071 6.55426 9.90667 7.1C9.91182 7.46538 9.84335 7.82805 9.70535 8.16641C9.56735 8.50476 9.36264 8.81187 9.10343 9.06942C8.84421 9.32698 8.5358 9.52971 8.19657 9.66553C7.85733 9.80136 7.49423 9.86749 7.12889 9.86ZM27.2711 27.2733H22.4622V18.9244C22.4622 16.4622 21.4156 15.7022 20.0644 15.7022C18.6378 15.7022 17.2378 16.7778 17.2378 18.9867V27.2733H12.4267V11.9889H17.0533V14.1067H17.1156C17.58 13.1667 19.2067 11.56 21.6889 11.56C24.3733 11.56 27.2733 13.1533 27.2733 17.82L27.2711 27.2733Z"
                fill="#0A66C2"
              />
            </g>
            <defs>
              <clipPath id="clip0_532_211">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>LinkedIn</p>
        </li>
        <li className="share-option" onClick={() => setCloseDropdown(true)}>
          <div className="other-option">
            <FontAwesomeIcon icon={faEllipsisH} />
          </div>
          <p>Other</p>
        </li>
      </ul> */}
      <div className="link-container">
        <p className="link-label">Link</p>
        <div className="link-input" onClick={handleShare}>
          <span className="copy-link">{textToCopy}</span>
          <button>
            <FontAwesomeIcon icon={faClone} />
          </button>
        </div>
      </div>
    </AnimatedDropdown>
  );
};

export default ShareModule;
