import React, { useState, useEffect } from "react";
import { renderContent } from "../../utils/editor";
import TagsUpdated from "../TagsUpdated/TagsUpdated";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";

const DescriptionTag = ({
  video,
  pressSeeMore = null,
  rowsDefault = 2,
  selectedOption = null,
  isDetailMode = false,
  type,
  isInline = false,
}) => {
  const [showMore, setShowMore] = useState(
    selectedOption === "expanded" ? true : null,
  );
  const [tagsLength, setTagsLength] = useState(null);
  const descriptionId = uuidv4();
  const [needsToBeExpanded, setNeedsToBeExpanded] = useState(null);

  const formatDescription = (description) => {
    try {
      const jsonDesc = JSON.parse(description);
      return renderContent(jsonDesc);
    } catch (err) {
      return description;
    }
  };

  useEffect(() => {
    if (tagsLength && tagsLength >= 2) {
      setNeedsToBeExpanded(true);
    }
  }, [tagsLength]);

  const descriptionToShow = formatDescription(video.description);

  useEffect(() => {
    const element = document.getElementById(descriptionId);
    if (element) {
      setNeedsToBeExpanded(
        element.scrollHeight > 16 + rowsDefault * 20 ? true : null,
      );
    }
  }, [video.description]);

  useEffect(() => {
    const checkTextOverflow = () => {
      const element = document.getElementById(descriptionId);
      if (element) {
        const needToBeExpanded =
          element.scrollHeight > 16 + rowsDefault * 20 && showMore === null
            ? true
            : null;
        setShowMore(needToBeExpanded);
      }
    };

    checkTextOverflow();

    window.addEventListener("resize", checkTextOverflow);

    return () => {
      window.removeEventListener("resize", checkTextOverflow);
    };
  }, [video.description]);

  if (type === "card") {
    return (
      <div className="description-tag">
        <div className="description">
          <div className="description-content">{video?.description}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="description-tag">
      <div className="description">
        {descriptionToShow[0] &&
          descriptionToShow[0].props &&
          descriptionToShow[0].props.children !== undefined && (
            <div
              id={descriptionId}
              className={`description-content${showMore ? " clipped" : ""}${rowsDefault === 1 && showMore !== null ? " one-row" : ""}`}
              style={{
                WebkitLineClamp: showMore ? rowsDefault : "unset",
              }}
            >
              <div
                className={` ${rowsDefault === 1 && showMore !== null ? "description-text one-row" : ""}`}
              >
                {descriptionToShow}
              </div>
              {isInline && showMore !== null && type !== "video-preview" && (
                <div
                  className="description-see-more"
                  onClick={() => {
                    pressSeeMore();
                  }}
                >
                  see more
                </div>
              )}
            </div>
          )}
        <div className="description-tag-tags">
          <TagsUpdated
            tags={video?.tags.map((tag) => tag.name)}
            filterTags={!isDetailMode || showMore}
            setTagsLength={setTagsLength}
          />
        </div>
        {!isInline &&
          (showMore !== null || (isDetailMode && needsToBeExpanded)) && (
            <div
              className="see-more-less"
              onClick={() => {
                if (!pressSeeMore) {
                  setShowMore(!showMore);
                } else {
                  pressSeeMore();
                }
              }}
            >
              {showMore === true ? "...see more" : "...see less"}
            </div>
          )}
      </div>
    </div>
  );
};

export default DescriptionTag;
