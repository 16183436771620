import React, { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import "./styles.scss";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import CrossSign from "../../assets/cross-sign.svg";
import useOutsideClick from "../../hooks/useOutsideClick";

const MIN_DIMENSION = 150;

const ImageCropper = ({
  imgSrc,
  handleClose,
  setImg,
  ASPECT_RATIO,
  setThumbnail,
  fileType,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();
  const popUpRef = useRef(null);
  useOutsideClick(popUpRef, () => handleClose());

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: width,
      },
      ASPECT_RATIO,
      width,
      height,
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <>
      {imgSrc && (
        <div className="img-crop-background-modal">
          <div
            className="flex flex-col items-center  img-crop-form"
            ref={popUpRef}
          >
            <div className="img-crop-form-header">
              Crop Thumbnail
              <button
                className="img-crop-cross-sign"
                onClick={() => handleClose()}
              >
                <img src={CrossSign} alt="X" />
              </button>
            </div>
            <div className="img-crop">
              <ReactCrop
                crop={crop}
                onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                aspect={ASPECT_RATIO}
                minWidth={MIN_DIMENSION}
              >
                <img
                  ref={imgRef}
                  src={imgSrc}
                  alt="Upload"
                  style={{ minHeight: "20vh", maxHeight: "50vh" }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              <ButtonComponent
                text={"Crop Image"}
                icon={false}
                handleClick={() => {
                  setCanvasPreview(
                    imgRef.current, // HTMLImageElement
                    previewCanvasRef.current, // HTMLCanvasElement
                    convertToPixelCrop(
                      crop,
                      imgRef.current.width,
                      imgRef.current.height,
                    ),
                  );
                  const dataUrl = previewCanvasRef.current.toDataURL();
                  setImg(dataUrl);
                  const filename =
                    fileType === "image/jpeg"
                      ? "thumbnail.jpg"
                      : "thumbnail.png";
                  previewCanvasRef.current.toBlob((blob) => {
                    const newFile = new File([blob], filename, {
                      type: fileType,
                    });
                    setThumbnail(newFile); // Use setThumbnail to set the file
                    handleClose(); // Close the modal
                  }, fileType);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: 150,
            height: 150,
          }}
        />
      )}
    </>
  );
};
export default ImageCropper;
