import React, { useState, useEffect, useRef } from "react";
import CodeBlock from "../CodeBlock/CodeBlock";
import { useObserver } from "../../hooks/useObserver";
import { renderContent, renderMixedText } from "../../utils/editor";
import "./styles.scss";

const CardComponent = ({
  cardInfo = {},
  answer1,
  answer2,
  answer3,
  answer4,
  setIsVisible = () => {},
  isFunctional = false,
  explanation = null,
  showCodeComponent,
  showImageComponent,
  isDetailMode = false,
}) => {
  const ref = useRef(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [chosenAnswer, setChosenAnswer] = useState(null);
  const isVisible = useObserver(ref);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [showFront, setShowFront] = useState(true);

  useEffect(() => {
    if (isFunctional) {
      const answers = [answer1, answer2, answer3, answer4].filter(Boolean);
      const shuffled = [...answers].sort(() => Math.random() - 0.5);
      setShuffledAnswers(shuffled);
    } else {
      setShuffledAnswers([answer1, answer2, answer3, answer4]);
    }
  }, [answer1, answer2, answer3, answer4]);

  useEffect(() => {
    setIsVisible(isVisible);
  }, [isVisible]);

  const handleResize = () => {
    if (ref.current) {
      const height = ref.current.offsetHeight;
      const width = (height * 9) / 16;
      ref.current.style.width = `${width}px`;
      ref.current.style.minWidth = `${width}px`;

      const fontSize = width / 28;
      ref.current.style.fontSize = `${fontSize}px`;
      ref.current.style.padding = `${1.5 * fontSize}px`;
      ref.current.style.gap = `${fontSize}px`;
      ref.current.style.borderRadius = `${2 * fontSize}px`;

      const baseSize = 360;
      const scaleCoef = width / baseSize;
      ref.current.style.setProperty("--scale-coef", scaleCoef);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [isDetailMode]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty("--color-schema", cardInfo.color);
    }
  }, [cardInfo.color]);

  const handleClick = () => {
    if (selectedAnswer === null || !isFunctional) return;
    setChosenAnswer(shuffledAnswers[selectedAnswer] === answer1);
    if (shuffledAnswers[selectedAnswer] === answer1) {
      document.getElementById("show-answer-button").style.backgroundColor =
        "green";
      document.getElementById("show-answer-button").innerText = "Correct!";
    } else {
      document.getElementById("show-answer-button").style.backgroundColor =
        "red";
      document.getElementById("show-answer-button").innerText = "Incorrect!";
    }
  };

  const renderAnswers = () => {
    return shuffledAnswers.map((answer, index) => (
      <>
        {(index < 2 || answer) && (
          <div
            key={index}
            className={`answer ${
              selectedAnswer === index && chosenAnswer === null
                ? "selected "
                : ""
            }${
              chosenAnswer !== null && answer === answer1
                ? "green"
                : selectedAnswer === index && chosenAnswer === false
                  ? "red"
                  : chosenAnswer !== null
                    ? "grey"
                    : ""
            }`}
            onClick={() => {
              if (chosenAnswer === null) {
                setSelectedAnswer(index);
              }
            }}
          >
            {renderMixedText({ text: answer })}
          </div>
        )}
      </>
    ));
  };

  const formatDescription = (description) => {
    try {
      return renderContent(JSON.parse(description));
    } catch (err) {
      return renderContent(description);
    }
  };

  const defineDescription = () => {
    if (
      cardInfo.card_type.toLowerCase() === "flashcard" &&
      showFront === false
    ) {
      if (cardInfo.descriptionBack)
        return formatDescription(cardInfo.descriptionBack);
      else return formatDescription(cardInfo.description_back);
    } else {
      return formatDescription(cardInfo.description);
    }
  };

  return (
    <div ref={ref} className="card-container">
      <div className="question">
        <div className="card-description">{defineDescription()}</div>
      </div>
      {cardInfo.image && showImageComponent && showFront && (
        <div className="image-container">
          <img src={cardInfo.image} alt="question" />
        </div>
      )}
      {cardInfo.imageBack && showImageComponent && !showFront && (
        <div className="image-container">
          <img src={cardInfo.imageBack} alt="question" />
        </div>
      )}
      {cardInfo.code_string && showCodeComponent && (
        <div className="code">
          <CodeBlock
            code_string={cardInfo.code_string}
            language={cardInfo.language}
          />
        </div>
      )}
      {(cardInfo.card_type === "Multiple Choice" ||
        cardInfo.card_type === "assessment") && (
        <div className="explanation-answer">
          {isFunctional && chosenAnswer !== null && explanation && (
            <div className="explanation">
              <span>Explanation: </span>
              {explanation}
            </div>
          )}
          <div className="answers">
            {renderAnswers()}
            <button
              className="show-answer"
              id="show-answer-button"
              onClick={handleClick}
            >
              Submit Answer
            </button>
          </div>
        </div>
      )}
      {cardInfo.card_type.toLowerCase() === "flashcard" && (
        <div className="explanation-answer">
          <div className="answers">
            <button
              className="show-answer"
              onClick={() => setShowFront(!showFront)}
            >
              Flip the card
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardComponent;
