import React from "react";
import "./styles.scss";

const Spinner = ({ size = "medium" }) => {
  /*
  Spinner component: corresponds to latest Figma design (May 15)
  */

  return (
    <div className="spinner-container">
      <div className={`spinner ${size === "small" ? "small" : ""}`}>
        <div className="empty-space"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default Spinner;
