import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const languageMap = {
  Python: "python",
  "C++": "cpp",
  Java: "java",
  JavaScript: "javascript",
  R: "r",
  SQL: "sql",
  HTML: "markup",
  CSS: "css",
};

const CodeBlock = ({ code_string, language }) => {
  return (
    <div>
      <SyntaxHighlighter language={languageMap[language]} style={atomDark}>
        {code_string}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
