import React, { useRef, useState } from "react";
import CrossSign from "../../assets/cross-sign.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import leftArrow from "../../assets/chevron-left.svg";
import "./styles.scss";
import FeedWrapper from "../../containers/FeedWrapper";
import Popup from "../../containers/Popup/Popup";

const VideoPreview = ({
  videoPreview,
  setVideoPreview,
  popout = false,
  handleClose = null,
}) => {
  /*
        Video preview utilized in the dashboard & AddVideoToPlaylistPopOut.
        - popout: boolean variable specifically created for AddVideoToPlaylistPopOut
        - handleClose: function to close the whole popout for AddVideoToPlaylistPopOut
    */
  const addRef = useRef(null);
  const [closePopup, setClosePopup] = useState(false);
  useOutsideClick(addRef, () => setClosePopup(true));

  return popout ? (
    <>
      <div className="video-preview-header">
        <button onClick={() => setVideoPreview(null)} className="return-button">
          <img src={leftArrow} />
          Go Back
        </button>
        <button className="video-add-cross-sign" onClick={handleClose}>
          <img src={CrossSign} alt="X" />
        </button>
      </div>
      <div className="video-preview-body">
        <FeedWrapper type="video-preview" video={videoPreview} />
      </div>
    </>
  ) : (
    <Popup
      className="video-add-wrapper"
      closePopup={closePopup}
      closeFunc={() => setVideoPreview(null)}
      withContainer
    >
      <div className="video-add-form popup" ref={addRef}>
        <div className="video-preview-header">
          <button
            className="video-add-cross-sign"
            onClick={() => setClosePopup(true)}
          >
            <img src={CrossSign} alt="X" />
          </button>
        </div>
        <div className="video-preview-body">
          <FeedWrapper type="video-preview" video={videoPreview} />
        </div>
      </div>
    </Popup>
  );
};
export default VideoPreview;
