import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { formatSimpleDate, parseViews } from "../../utils/video";
import TabComponent from "../../components/TabComponent/TabComponent.js";
import FilterList from "../../components/FilterList/FilterList";
import AuthorAvatar from "../../components/AuthorAvatar/AuthorAvatar";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import ListVideoComponent from "../../components/ListVideoComponent/ListVideoComponent";
import Spinner from "../../components/Spinner/Spinner";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";
import { videoService } from "../../services/video.service.ts";
import { playlistService } from "../../services/playlist.service.ts";
import { renderContent } from "../../utils/editor.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotFoundPage from "../NotFoundPage/NotFoundPage.js";
import { cardService } from "../../services/card.service.ts";

const CreatorPage = () => {
  /*
    It's a creator profile page showing the key information about the creator, their statistics and other relevant information

    todo for backend:
    1. Create username on the backend and use it for url instead of id
    2. Edit an endpoint, which would return data about the user based on their id
        2.1. Avatar
        2.2. Description
        2.3. Number of subscribers
        2.4. When the user joined
        2.5. Username
    3. Create an endpoint that checks if currently auth user is subscribed for the other user
        3.1. maybe just list of all the channels on which they are subscribed
    4. Add more information for playlists
        4.1. Number of views

    todo for frontend:
    1. Redefine routing based on username
    */

  const tabs = ["All", "Videos", "Cards", "Playlists"];
  const [activeTab, setActiveTab] = useState("All");
  const [name, setName] = useState("");
  const [username, setUsername] = useState(null);
  const [channelName, setChannelName] = useState(null);
  // const [isSubscribed, setIsSubscribed] = useState(false);
  const [description, setDescription] = useState(
    "No channel description available.",
  );
  // const [numberOfSubscribers, setNumberOfSubscribers] = useState(0);
  const [numberOfVideos, setNumberOfVideos] = useState(0);
  const [numberOfPlaylists, setNumberOfPlaylists] = useState(0);
  const [joinedDate, setJoinedDate] = useState("");
  const [userCategories, setUserCategories] = useState([]);
  const [videoCategories, setVideoCategories] = useState([]);
  const [playlistCategories, setPlaylistCategories] = useState([]);
  const [cardsCategories, setCardsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [cards, setCards] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [filteredPlaylists, setFilteredPlaylists] = useState([]);
  const location = useLocation();
  const { channelUsername } = useParams();
  const [channelUuid, setChannelUuid] = useState();
  const [userData, setUserData] = useState();
  const [notFound, setNotFound] = useState(null);
  const userId = useSelector((state) => state.auth.user?.id);
  const navigate = useNavigate();

  const [numberOfCards, setNumberOfCards] = useState(0);

  const fetchUserVideos = async () => {
    try {
      const response = await videoService.getUserVideos(channelUuid);
      setVideos(response);
      setNumberOfVideos(response.length);
    } catch (error) {
      console.error("Error fetching user videos:", error);
    }
  };

  const fetchUserCards = async () => {
    try {
      const response = await cardService.getChannelCards(channelUuid);
      setCards(response);
      setNumberOfCards(response.length);
    } catch (error) {
      console.error("Error fetching user videos:", error);
    }
  };

  const fetchUserPlaylists = async () => {
    try {
      const response = await playlistService.getUserPlaylists(channelUuid);
      const showPlaylists = response.filter(
        (playlist) => playlist.video_count > 0,
      );
      setNumberOfPlaylists(showPlaylists.length);
      showPlaylists.sort((a, b) => b.video_count - a.video_count);
      setPlaylists(showPlaylists);
    } catch (error) {
      console.error("Error fetching user playlists:", error);
    }
  };

  const fetchUserCategories = () => {
    const video_data = videos.reduce((acc, item) => {
      const categoryNames = item?.categories?.map((category) => category.name);
      return acc.concat(categoryNames);
    }, []);

    const playlist_data = playlists.reduce((acc, item) => {
      return acc.concat(item.categories);
    }, []);

    const card_data = cards.reduce((acc, item) => {
      const categoryNames = item?.categories?.map((category) => category.name);
      return acc.concat(categoryNames);
    }, []);

    const all_data = [...video_data, ...playlist_data, ...card_data];

    const uniqueSorted = (data) =>
      [...new Set(data)]
        .filter((category) => category && category.trim() !== "")
        .sort();

    setVideoCategories(["All", ...uniqueSorted(video_data)]);
    setPlaylistCategories(["All", ...uniqueSorted(playlist_data)]);
    setCardsCategories(["All", ...uniqueSorted(card_data)]);
    setUserCategories(["All", ...uniqueSorted(all_data)]);
  };

  const fetchUser = async () => {
    try {
      setNotFound(null);
      const response = await userService.getUser(channelUsername.toLowerCase());
      setUserData(response);
      setChannelUuid(response.id);
      // setNumberOfSubscribers(response.subscribersCount);
      setName(response.first_name + " " + response.last_name);
      setUsername(response.username);
      setChannelName(response.channel_name);
      setJoinedDate(
        formatSimpleDate(new Date(response.created_at), {
          month: "short",
          year: "numeric",
        }),
      );
      setDescription(response.introduction);
    } catch (error) {
      setNotFound("user");
      console.error("Error fetching channel info:", error);
    }
  };
  // const handleSubscribe = () => {
  //     setIsSubscribed(!isSubscribed);
  //     //Finish once people can visit each other's channels
  // };

  const handleClick = (category) => {
    setSelectedCategories([category]);
  };

  useEffect(() => {
    fetchUser();
  }, [channelUsername]);

  useEffect(() => {
    if (channelUuid) {
      fetchUserVideos();
      fetchUserCards();
      fetchUserPlaylists();
    }
  }, [channelUuid]);

  useEffect(() => {
    fetchUserCategories();
  }, [videos, cards, playlists]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const validTabs = ["All", "Videos", "Cards", "Playlists"];

    if (tab && validTabs.includes(tab)) {
      setActiveTab(tab);
      setSelectedCategories(["All"]);
    } else {
      setActiveTab("All");
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedCategories.includes("All")) {
      setFilteredVideos(videos);
    } else {
      let resultedMedia = [];
      videos.map((media) => {
        if (
          media?.categories &&
          media?.categories.some((category) =>
            selectedCategories.includes(category.name),
          )
        ) {
          resultedMedia.push(media);
        }
      });
      setFilteredVideos(resultedMedia);
    }
  }, [videos, selectedCategories]);

  useEffect(() => {
    if (selectedCategories.includes("All")) {
      setFilteredPlaylists(playlists);
    } else {
      let resultedPlaylists = [];
      playlists.map((media) => {
        if (
          media?.categories &&
          media?.categories.some((category) =>
            selectedCategories.includes(category),
          )
        ) {
          resultedPlaylists.push(media);
        }
      });
      setFilteredPlaylists(resultedPlaylists);
    }
  }, [playlists, selectedCategories]);

  const filterPopular = () => {
    if (!filteredVideos) return [];

    return filteredVideos
      .filter((video) => video.views)
      .sort((a, b) => b.views - a.views);
  };

  const filterTrending = () => {
    // Trending score = views / days old
    if (!filteredVideos) return [];

    const now = new Date();

    return filteredVideos
      .filter((video) => video.views && video.date)
      .map((video) => {
        const daysOld = Math.ceil(
          (now - new Date(video.date)) / (1000 * 60 * 60 * 24),
        );
        return {
          ...video,
          trendScore: video.views / daysOld,
        };
      })
      .sort((a, b) => b.trendScore - a.trendScore);
  };

  const filterAll = () => {
    if (!filteredVideos) return [];

    return filteredVideos
      .filter((video) => video.date)
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const formatDescription = (description) => {
    try {
      const jsonDesc = JSON.parse(description);
      const renderedDesc = renderContent(jsonDesc);
      if (renderedDesc[0].props.children) {
        return renderContent(jsonDesc);
      } else {
        return "No channel description available.";
      }
    } catch (err) {
      return description?.length > 0
        ? description
        : "No channel description available.";
    }
  };

  const getCategoriesByTab = () => {
    const categoriesMap = {
      All: userCategories,
      Playlists: playlistCategories,
      Videos: videoCategories,
      Cards: cardsCategories,
    };

    const categories = categoriesMap[activeTab] || [];
    return categories.length > 1 ? categories : [];
  };

  return notFound ? (
    <NotFoundPage pageType={notFound} />
  ) : (
    <div className="creator-page">
      <div className="creator-page-top">
        <div className="avatar">
          <AuthorAvatar author={userData} />
        </div>
        <div className="description">
          <div className="name">
            {channelName && channelName.length > 0 ? channelName : name}
            {username && <div className="username">@{username}</div>}
          </div>
          <div className="statistics">
            {/* <div>{parseViews(numberOfSubscribers || 0)} Subscribers</div> */}
            {/* <div className="vertical-line"></div> */}
            <div> {parseViews(numberOfVideos || 0)} Videos</div>
            <div className="vertical-line"></div>
            <div> {parseViews(numberOfCards || 0)} Cards</div>
            <div className="vertical-line"></div>
            <div> {parseViews(numberOfPlaylists || 0)} Playlists</div>
            {joinedDate && (
              <>
                <div className="vertical-line"></div>
                <div> {`Joined: ${joinedDate}`}</div>
              </>
            )}
          </div>
          <div className="description">{formatDescription(description)}</div>
          {/* <div className="subscribe">
                  <ButtonComponent text={isSubscribed? "Unsubscribe" : "Subscribe"} level = {isSubscribed? "secondary" : "primary"} icon={false} handleClick = {handleSubscribe} />
              </div> */}
          {userId === channelUuid && (
            <div className="subscribe">
              <ButtonComponent
                text={"Edit Bio"}
                level="primary"
                icon={false}
                handleClick={() => navigate("/settings?tab=PublicProfile")}
                size="small"
              />
            </div>
          )}
        </div>
      </div>

      <div className="video-playlist-filtering">
        <TabComponent tabs={tabs} defaultTab="All" />
      </div>
      <div className="categories-filtering">
        <FilterList
          categories={getCategoriesByTab()}
          selectedCategories={selectedCategories}
          handleClick={handleClick}
        />
      </div>
      {filteredVideos && filteredPlaylists ? (
        <div className="videos">
          <div className="Popular">
            {filterPopular().length > 0 &&
              (activeTab === "All" || activeTab === "Videos") && (
                <ListVideoComponent items={filterPopular()} heading="Popular" />
              )}
          </div>
          <div className="Trending">
            {filterTrending().length > 0 &&
              (activeTab === "All" || activeTab === "Videos") && (
                <ListVideoComponent
                  items={filterTrending()}
                  heading="Trending"
                />
              )}
          </div>
          <div className="All">
            {(activeTab === "All" || activeTab === "Videos") &&
              (filteredVideos.length > 0 ? (
                <ListVideoComponent items={filterAll()} heading="All" />
              ) : activeTab === "Videos" ? (
                <div className="no-content-text">
                  {" "}
                  @{channelUsername.toLowerCase()} doesn’t have any videos.
                </div>
              ) : (
                filteredPlaylists.length === 0 && (
                  <div className="no-content-text">
                    {" "}
                    @{channelUsername.toLowerCase()} doesn’t have any content or
                    playlist.
                  </div>
                )
              ))}
          </div>
          <div>
            {["Cards", "All"].includes(activeTab) &&
              (cards.length > 0 ? (
                <ListVideoComponent
                  items={cards}
                  author={userData}
                  heading="Cards"
                  type={"cards"}
                />
              ) : (
                activeTab === "Cards" && (
                  <div className="no-content-text">
                    {" "}
                    @{channelUsername.toLowerCase()} doesn’t have any cards.
                  </div>
                )
              ))}
          </div>
          <div>
            {["Playlists", "All"].includes(activeTab) &&
              (filteredPlaylists.length > 0 ? (
                <ListVideoComponent
                  items={filteredPlaylists}
                  author={userData}
                  heading="Playlists"
                  type={"playlists"}
                />
              ) : (
                activeTab === "Playlists" && (
                  <div className="no-content-text">
                    {" "}
                    @{channelUsername.toLowerCase()} doesn’t have any playlists.
                  </div>
                )
              ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CreatorPage;
