// AuthorInfo.js
import React from "react";
import "./styles.scss";
import { formatSimpleDate, parseViews } from "../../utils/video";
import AuthorAvatar from "../AuthorAvatar/AuthorAvatar";

const AuthorInfo = ({ author, views, date }) => {
  /*
    Component that displays basic info on an author, including:
      - Avatar
      - Channel name/ User's name
      - Views for this author's video
      - Date posted for this author's video
    [TBD]: Subscriber count
  */

  return author ? (
    <div className="avatar-component">
      <div className="person-info">
        <div className="avatar">
          <AuthorAvatar author={author} />
        </div>
        <div className="subscriber-info">
          <div className="author-name">
            {author.channel_name?.length > 0
              ? author.channel_name
              : author.first_name + " " + author.last_name}
          </div>
          {/* {orientation === "portrait" && (
            <p className="h7">{author.subscribersCount} Subscribers</p>
          )} */}
          <div className="author-metadata">
            {/* {orientation === "landscape" && (
              <p>{parseViews(author.subscribersCount)} Subscribers</p>
            )} */}
            <p>{parseViews(views)} views</p>
            <p>{formatSimpleDate(date)}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AuthorInfo;
