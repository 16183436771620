import React from "react";
import videojs from "video.js";
import { useDispatch } from "react-redux";
import { setVideoPlayerRef, setVideoPlayerRefs } from "../../actions/video";
import "video.js/dist/video-js.css";

export const VideoJS = (props) => {
  /*
    Video.JS player.

    props:
      - options: configuration of the player, including autoplay, fill, aspect ratio, etc.
      - onReady: function that handles events relevant to the player (pause, play, etc).
      - isVisible: visibility of the component.
  */
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { id, options, onReady, isVisible } = props;
  const dispatch = useDispatch();
  const [lastVisibilityState, setLastVisibilityState] =
    React.useState(isVisible);

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      if (options.autoplay && isVisible && !lastVisibilityState) {
        var playPromise = playerRef.current.play();
        playPromise?.catch((error) => {
          console.error(error);
        });
      }
    }
  }, [options, onReady]);

  React.useEffect(() => {
    setLastVisibilityState(isVisible);
    if (isVisible === true) {
      dispatch(setVideoPlayerRef(playerRef.current));
    }
  }, [dispatch, isVisible]);

  React.useEffect(() => {
    const player = playerRef.current;
    dispatch(setVideoPlayerRefs({ [id]: player }));

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
