import { SET_VIDEO_SPEED } from "./types";
import { SET_AUDIO_LEVEL } from "./types";
import { SET_MUTE } from "./types";
import { SET_AUTOPLAY } from "./types";

export const setVideoSpeed = (speed) => ({
  type: SET_VIDEO_SPEED,
  payload: speed,
});

export const setAudioLevel = (audio) => ({
  type: SET_AUDIO_LEVEL,
  payload: audio,
});

export const setMute = (isMuted) => ({
  type: SET_MUTE,
  payload: isMuted,
});

export const setAutoplay = (autoplay) => ({
  type: SET_AUTOPLAY,
  payload: autoplay,
});
