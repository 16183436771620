import {
  SET_CURRENT_TIME,
  SET_VIDEO_PLAYER_REF,
  SET_CURRENT_VIDEO,
  ADD_VIDEO_LIKE,
  REMOVE_VIDEO_LIKE,
  SET_VIDEO_PLAYER_REFS,
} from "./types";

export const setCurrentTime = (time) => ({
  type: SET_CURRENT_TIME,
  payload: time,
});

export const setVideoPlayerRef = (playerRef) => ({
  type: SET_VIDEO_PLAYER_REF,
  payload: playerRef,
});

export const setVideoPlayerRefs = (playerRefs) => ({
  type: SET_VIDEO_PLAYER_REFS,
  payload: playerRefs,
});

export const setCurrentVideo = (video) => ({
  type: SET_CURRENT_VIDEO,
  payload: video,
});

export const addVideoLike = (videoId) => ({
  type: ADD_VIDEO_LIKE,
});

export const removeVideoLike = (videoId) => ({
  type: REMOVE_VIDEO_LIKE,
});
