import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import chevronLeft from "../../assets/chevron-left.svg";
import chevronRight from "../../assets/chevron-right.svg";

const FilterList = ({
  categories,
  selectedCategories,
  handleClick,
  areCountsVisible,
  categoriesCount,
}) => {
  /* FilterList component that is a horizontal list of filter items
        Alligned with the latest Figma design (May 24)
        categories: array of strings that represent the filter items
        selectedCategories: array of strings that represent the selected filter items
        handleClick: callback prop to be called when a filter item is clicked
     */
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const categoriesListRef = useRef(null);
  const categoryRefs = useRef([]);

  const scrollPrev = () => {
    const currentScrollPosition = categoriesListRef.current.scrollLeft;
    const prevCategoryIndex = categoryRefs.current
      .slice()
      .reverse()
      .findIndex((ref) => ref.offsetLeft < currentScrollPosition);
    if (prevCategoryIndex !== -1) {
      const adjustedIndex = categories.length - prevCategoryIndex - 1;
      const prevCategory = categoryRefs.current[adjustedIndex];
      categoriesListRef.current.scrollBy({
        left: -(prevCategory.offsetWidth + 8),
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const checkButtonsVisibility = () => {
      if (!categoriesListRef.current) return;
      const { offsetWidth, scrollLeft, scrollWidth } =
        categoriesListRef.current;
      setShowNextButton(Math.ceil(offsetWidth + scrollLeft) < scrollWidth);
      setShowPrevButton(scrollLeft > 0);
    };

    checkButtonsVisibility();
    window.addEventListener("resize", checkButtonsVisibility);

    categoriesListRef.current.addEventListener(
      "scroll",
      checkButtonsVisibility,
    );
    return () => {
      window.removeEventListener("resize", checkButtonsVisibility);
      categoriesListRef.current?.removeEventListener(
        "scroll",
        checkButtonsVisibility,
      );
    };
  }, [categories]);

  const scrollNext = () => {
    const currentScrollPosition = categoriesListRef.current.scrollLeft;
    const nextCategoryIndex = categoryRefs.current.findIndex(
      (ref) => ref.offsetLeft > currentScrollPosition,
    );
    if (nextCategoryIndex !== -1) {
      const nextCategory = categoryRefs.current[nextCategoryIndex];
      const offset =
        nextCategoryIndex === 0
          ? nextCategory.offsetWidth - 8
          : nextCategory.offsetWidth + 8;
      categoriesListRef.current.scrollBy({
        left: offset,
        behavior: "smooth",
      });
    }
  };

  const displayCategory = (category) => {
    if (areCountsVisible) {
      return `${category} (${categoriesCount[category]})`;
    } else {
      return category;
    }
  };

  return (
    <div className="filter-list-component">
      <ul
        className="category-filter-list hidden-scrollbar"
        ref={categoriesListRef}
      >
        {categories.map((category, index) => (
          <li
            key={index}
            className={`filter-list-item ${selectedCategories.includes(category) ? "selected-item" : ""}`}
            onClick={() => handleClick(category)}
            ref={(el) => (categoryRefs.current[index] = el)}
          >
            {displayCategory(category)}
          </li>
        ))}
        {showPrevButton && (
          <button
            className="scroll-nav-button prev-button"
            onClick={scrollPrev}
          >
            <img src={chevronLeft} alt="Previous" />
          </button>
        )}
        {showNextButton && (
          <button
            className="scroll-nav-button next-button"
            onClick={scrollNext}
          >
            <img src={chevronRight} alt="Next" />
          </button>
        )}
      </ul>
    </div>
  );
};

export default FilterList;
