import React, { useEffect, useState } from "react";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import { useSelector } from "react-redux";
import "./styles.scss";

const AccountInfoTab = ({ setReadyToSave, setUserInfo }) => {
  /*
        One of the settings components.
        Displays user's first name, last name, email, and password.
        Fully alligned with Figma design (June 2).
    */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [channelName, setChannelName] = useState("");
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user === null) {
      return;
    }
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setUsername(user.username || "");
    setChannelName(user.channel_name || "");
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      firstName: user.first_name,
      lastName: user.last_name,
      username: user.username,
    }));
  }, [user]);

  useEffect(() => {
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.includes("@") &&
      username.length > 0
    ) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  }, [firstName, lastName, email, username]);

  const channelNameText = (
    <div className="optional-container">
      Channel Name <p className="optional-text">(optional)</p>
    </div>
  );

  return (
    <div className="account-info">
      <div className="account-info-headline">Account Info</div>

      <div className="name">
        <div className="first">
          <TextInputComponent
            label="First name"
            handleInput={(e) => {
              setFirstName(e.target.value);
              setUserInfo((prevUserInfo) => ({
                ...prevUserInfo,
                firstName: e.target.value,
              }));
            }}
            placeholder="Enter your name"
            initialValue={firstName}
          />
        </div>
        <div className="first">
          <TextInputComponent
            label="Last name"
            handleInput={(e) => {
              setLastName(e.target.value);
              setUserInfo((prevUserInfo) => ({
                ...prevUserInfo,
                lastName: e.target.value,
              }));
            }}
            placeholder="Enter your name"
            initialValue={lastName}
          />
        </div>
      </div>
      <div className="first">
        <TextInputComponent
          label={channelNameText}
          handleInput={(e) => {
            const newChannelName = e.target.value;
            setChannelName(newChannelName);
            setUserInfo((prevUserInfo) => ({
              ...prevUserInfo,
              channelName: newChannelName,
            }));
          }}
          placeholder="Enter your channel name"
          initialValue={channelName}
        />
      </div>
      <div className="first">
        <TextInputComponent
          label="Username"
          handleInput={(e) => {
            const newUsername = e.target.value
              .slice(0, 15)
              .toLowerCase()
              .replace(/[^a-z0-9_]/g, "");
            setUsername(newUsername);
            setUserInfo((prevUserInfo) => ({
              ...prevUserInfo,
              username: newUsername,
            }));
          }}
          placeholder="Enter a unique username"
          initialValue={username}
        />
      </div>
      <div className="first">
        <TextInputComponent
          label="Email address"
          handleInput={(e) => {
            setEmail(e.target.value);
            setUserInfo((prevUserInfo) => ({
              ...prevUserInfo,
              email: e.target.value,
            }));
          }}
          placeholder="Enter your email address"
          initialValue={email}
          disabled={true}
          toolTip={true}
          toolTipText="You will not be able to change your email in case you used social logins."
        />
      </div>
    </div>
  );
};

export default AccountInfoTab;
