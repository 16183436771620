import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPlaylist,
  updatePlaylistStructure,
} from "../../actions/playlist";
import { showModal, notifyError } from "../../actions/global.action.js";
import ItemCard from "../ItemCard/ItemCard.js";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const PlaylistPreview = ({ playlist, newPlaylist = false }) => {
  /*
        Playlist Preview displayed when editing the playlist.
        Accessible from  Dashboard > My Playlists and /playlists, in the rightmost column.
        Displays all videos in a selected playlist:
            - Thumbnail
            - Title
            - Author
            - Date
            - View count
        Contains a button to add more videos to the selected playlist.    
    */
  const dispatch = useDispatch();
  const items = useSelector((state) => state.playlist.playlists);

  const [videoPreview] = useState(null);
  const handleClick = () => {
    if (newPlaylist) {
      dispatch(notifyError("Save playlist to add videos"));
    } else if (playlist?.id) {
      dispatch(setCurrentPlaylist(playlist));
      dispatch(updatePlaylistStructure(items, playlist));
    }
    dispatch(showModal("add-video-to-playlist"));
  };

  useEffect(() => {
    if (videoPreview !== null) {
      dispatch(showModal("show-video-preview", videoPreview));
    }
  }, [videoPreview]);

  const renderPlaylistItems = (playlist) => {
    return playlist?.children?.map((item) => {
      if (item.children) {
        return renderPlaylistItems(item);
      } else {
        return (
          <ItemCard
            key={item.object_id}
            object={item}
            level="small"
            handleClick={() => {
              window.open(
                item.video_url
                  ? `/video/${item.object_id}`
                  : `/card/${item.object_id}`,
                "_blank",
              );
            }}
          />
        );
      }
    });
  };

  return (
    <div className="preview-wrapper">
      <div className="preview-top">
        <div className="preview-header">Playlist Preview</div>
        <ButtonComponent
          text="Add items"
          handleClick={handleClick}
          icon={false}
          size="small"
          level={newPlaylist ? "disable" : "primary"}
        />
      </div>
      <div className="preview-deck">
        {!newPlaylist && renderPlaylistItems(playlist)}
      </div>
    </div>
  );
};
export default PlaylistPreview;
