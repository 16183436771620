import videojs from "video.js";

const Component = videojs.getComponent("Component");
/*
    Creates a custom time display to show the current time and the total duration of the video
 */

class CustomTimeDisplay extends Component {
  constructor(player, options) {
    super(player, options);

    this.player_ = player;
    this.updateTimeDisplay = this.updateTimeDisplay.bind(this);
    this.clearTimeDisplay = this.clearTimeDisplay.bind(this);

    this.player_.on("timeupdate", this.updateTimeDisplay);
    this.player_.on("loadedmetadata", this.updateTimeDisplay);
    this.player_.on("fullscreenchange", this.clearTimeDisplay);

    this.createEl();
  }

  createEl() {
    this.el_ = videojs.dom.createEl("div", {
      className: "vjs-custom-time-display",
    });

    this.updateTimeDisplay();
    return this.el_;
  }

  updateTimeDisplay() {
    const currentTime = this.player_.currentTime();
    const duration = this.player_.duration();

    const formattedCurrentTime = this.formatTime(currentTime);
    const formattedDuration = this.formatTime(duration);

    this.el_.innerHTML = `${formattedCurrentTime} / ${formattedDuration}`;
  }

  clearTimeDisplay() {
    const currentTimeDisplay =
      this.player_.controlBar.getChild("currentTimeDisplay");
    const durationDisplay = this.player_.controlBar.getChild("durationDisplay");

    if (this.player_.isFullscreen()) {
      currentTimeDisplay.hide();
      durationDisplay.hide();
    } else {
      currentTimeDisplay.show();
      durationDisplay.show();
      this.updateTimeDisplay();
    }
  }

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }
}

export default CustomTimeDisplay;
