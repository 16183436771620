import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  hideModal,
  showModal,
  notifyError,
  notifySuccess,
} from "../../actions/global.action";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import DropdownMenuPrivacy from "../../components/DropdownMenuPrivacy/DropdownMenuPrivacy";
import TextInputComponent from "../../components/TextInputComponent/TextInputComponent";
import MultipleComponent from "../../components/MultipleComponent/MultipleComponent";
import useOutsideClick from "../../hooks/useOutsideClick";
import UploadFile from "./UploadFile";
import CrossSign from "../../assets/cross-sign.svg";
import SuccessIcon from "../../assets/success-icon.svg";
import { setUserVideoData } from "../../actions/data";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";
import { videoService } from "../../services/video.service.ts";
import RichTextEditorComponent from "../../components/RichTextEditor/RichTextEditorComponent.js";
import { countChar } from "../../utils/editor.js";
import Popup from "../../containers/Popup/Popup.js";
import { formatSeconds } from "../../utils/video.js";
import ThumbnailChangeComponent from "../../components/ThumbnailChangeComponent/ThumbnailChangeComponent.js";
import AlertModal from "../AlertModal/AlertModal";

const UploadPopUpForm = ({ handleClose }) => {
  /*
    Upload Form that handles all the processes from video uploading to modifying information
    Alligned with latest Figma design (May 15)
    todo: add warning message when user tries to close the page without saving the video
    */

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visibility, setVisibility] = useState("public");
  // const [comments, setComments] = useState(false);
  const [videoType, setVideoType] = useState("landscape");
  const [loaded, setLoaded] = useState("not loaded");
  const [videoFile, setVideoFile] = useState(null);
  const [details, setDetails] = useState(false);
  const [readyToPublish, setReadyToPublish] = useState("disable");
  const [video, setVideo] = useState(null);
  const [closePopup, setClosePopup] = useState(false);
  const [duration, setDuration] = useState();
  const [fileName, setFileName] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [img, setImg] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [showAlarmModal, setShowAlarmModal] = useState(false);
  const uploadRef = useRef(null);
  const location = useLocation();
  useOutsideClick(uploadRef, () => handleClosingModal());

  const handleClosingModal = () => {
    if (location.pathname !== "/upload") {
      if (loaded === "not loaded" || loaded === "publishing") {
        setClosePopup(true);
      }
      setShowAlarmModal(true);
    }
  };

  const handleVideoUpload = (file) => {
    setVideoFile(file);
  };

  const handleVideoDetails = () => {
    setDetails(true);
  };

  useEffect(() => {
    if (videoFile != null) {
      handleSubmit();
    }
  }, [videoFile]);

  useEffect(() => {
    if (
      loaded === "publishing" ||
      !title ||
      !description ||
      !tags.length ||
      !categories.length
    ) {
      setReadyToPublish("disable");
    } else {
      setReadyToPublish("primary");
    }
  }, [title, description, tags, categories, loaded]);
  const fetchVideos = async () => {
    try {
      const response = await userService.getUserVideos();

      dispatch(setUserVideoData(response));
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    dispatch(showModal());
    return () => {
      dispatch(hideModal());
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    if (videoFile.size > 100 * 1024 * 1024) {
      dispatch(notifyError("File size should be less than 100 MB"));
      return;
    }
    setLoaded("loading");

    const formData = new FormData();
    formData.append("video_file", videoFile);
    try {
      const response = await videoService.preprocessVideo(formData);
      const formattedDescription = {
        type: "doc",
        content: [
          {
            type: "paragraph",
            content: [{ type: "text", text: response.description }],
          },
        ],
      };
      setTitle(response.title);
      setDescription(formattedDescription);
      let cleanedTags = response.tags.map((tag) =>
        tag.replace(/[!@#$%^&*()+=[\]{}|\\:;"'<>,.?/ ]/g, "").trim(),
      );
      setTags(cleanedTags);
      setVideoType(response.video_type);
      setVideo(response);
      setDimensions(response.dimensions);
      setDuration(response.duration);
      setFileName(videoFile.name);
      setCategories(response.categories);

      notifySuccess("Video uploaded successfully");
      setLoaded("loaded");
      handleVideoDetails();
    } catch (error) {
      const errorMessage = error.response.data.error || "Video upload failed";
      console.error("Error:", error);
      dispatch(notifyError(errorMessage));
      setLoaded("not loaded");
    }
  };

  const createTagOutput = () => {
    let output = "";
    for (let tag of tags) {
      tag = tag.replaceAll(" ", "");
      output += "#" + tag + " ";
    }
    output.slice(0, output.length - 1);
    return output;
  };

  const handleInputTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleInputLearningObjective = (tags) => {
    setCategories(tags);
  };

  const handleTagChange = (tags) => {
    setTags(tags);
  };

  // const handleCommentSwitch = () => {
  //   setComments(!comments);
  // };

  const handleVisibilityChange = (selectedOption) => {
    setVisibility(selectedOption);
  };

  const handlePublishClick = async () => {
    if (readyToPublish === "disable") {
      return;
    }
    if (title.length > 100) {
      dispatch(notifyError("Title should be no longer than 100 characters!"));
      return;
    }
    const currDescLength = countChar(description);
    if (currDescLength > 500) {
      dispatch(
        notifyError("Description should be no longer than 500 characters!"),
      );
      return;
    }
    setLoaded("publishing");
    const jsonDesc = JSON.stringify(description);
    const formData = new FormData();
    formData.append("id", video.id);
    formData.append("title", title);
    formData.append("description", jsonDesc);
    formData.append("tags", tags);
    formData.append("categories", categories.join(","));
    formData.append("visibility", visibility);
    formData.append("video_file", videoFile);
    formData.append("thumbnail_url", video.thumbnail_url);
    if (thumbnail) {
      formData.append("thumbnail_file", thumbnail);
    }
    try {
      await videoService.createVideo(formData);
      dispatch(notifySuccess("Video saved successfully!"));
    } catch (error) {
      dispatch(notifyError("Video save failed"));
      console.error("Error:", error);
    }
    setLoaded("loaded");
    fetchVideos();
    navigate("/dashboard");
    setClosePopup(true);
  };

  function formatTitle(title) {
    if (title.length > 55) {
      let trimmed = title.substring(0, 55);
      let lastSpace = trimmed.lastIndexOf(" ");
      if (lastSpace > 0) {
        return title.substring(0, lastSpace) + "...";
      }
      return title.substring(0, 55) + "...";
    }

    return title;
  }

  return (
    <Popup
      className="upload-popup-form-background-modal"
      closePopup={closePopup}
      closeFunc={handleClose}
      withContainer={true}
    >
      <div className="upload-popup-form popup" ref={uploadRef}>
        <div className="upload-popup-form-header">
          Upload Video
          <button
            className="upload-popup-form-cross-sign"
            onClick={() => handleClosingModal()}
          >
            <img src={CrossSign} alt="X" />
          </button>
        </div>
        {details ? (
          <>
            <div className="upload-popup-form-body">
              <div className="upload-popup-form-body-left">
                {loaded === "publishing" ? (
                  <div className="upload-popup-form-body-insert">
                    <UploadFile
                      state={loaded}
                      handleVideoUpload={handleVideoUpload}
                      handleVideoDetails={handleVideoDetails}
                      imageType={videoType}
                    />
                  </div>
                ) : (
                  <>
                    <div className="upload-popup-form-body-header">
                      Video Details
                    </div>
                    <div className="upload-popup-form-preview">
                      <ThumbnailChangeComponent
                        video={video}
                        img={img}
                        setImg={setImg}
                        setThumbnail={setThumbnail}
                      />
                      <div className="upload-popup-form-preview-right">
                        <div
                          className={`upload-popup-form-preview-right-title ${title ? "has-title" : ""}`}
                        >
                          {title ? formatTitle(title) : "Untitled"}
                        </div>
                        <div
                          className={`upload-popup-form-preview-right-tag ${tags.length ? "has-tag" : ""}`}
                        >
                          {tags.length ? createTagOutput() : "#Tag"}
                        </div>
                        <div className="upload-popup-form-preview-right-divider"></div>
                        <div className="upload-popup-form-preview-right-visibility">
                          <div className="upload-popup-form-preview-right-visibility-word">
                            Visibility
                          </div>
                          <DropdownMenuPrivacy
                            category="privacy"
                            currentVisibility={visibility}
                            handleVisibilityChange={handleVisibilityChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="upload-popup-form-body-title">
                      <TextInputComponent
                        label="Title"
                        state="Default"
                        icon="false"
                        handleInput={handleInputTitle}
                        placeholder="Write a title"
                        initialValue={video.title}
                        restriction={true}
                        toolTip={true}
                        toolTipText="The video title must be less than 100 characters."
                      />
                    </div>
                    <div className="upload-popup-form-body-description">
                      <RichTextEditorComponent
                        title={"Description"}
                        object={video}
                        setContent={setDescription}
                      />
                    </div>
                    <div className="upload-popup-form-body-learning-objective">
                      <MultipleComponent
                        label="Categories"
                        placeholderText="Enter your categories"
                        initialInput={categories}
                        handleTagChange={handleInputLearningObjective}
                        categorySelector={true}
                        limit={3}
                      />
                    </div>
                    <div className="upload-popup-form-body-tag">
                      <MultipleComponent
                        label="Tag"
                        handleTagChange={handleTagChange}
                        placeholder="Enter your tag"
                        initialInput={tags}
                        isLowerCased={true}
                      />
                    </div>
                    {/* The following piece of code is not used for the MVP, but will be used later */}
                    {/* <div className="upload-popup-form-body-comments">
                    Disable Comments
                    <ToggleComponent initialState = {comments} handleToggleSwitch = {handleCommentSwitch} />
                </div> */}
                  </>
                )}
              </div>
              <div className="upload-popup-form-body-right">
                <div className="pre-publish-block">
                  <div className="pre-publish-block-info">
                    <div className="pre-publish-block-headline"> Filename:</div>
                    <div className="pre-publish-block-description">
                      {fileName}
                    </div>
                  </div>
                  <div className="pre-publish-block-info">
                    <div className="pre-publish-block-headline"> Duration:</div>
                    <div className="pre-publish-block-description">
                      {formatSeconds(duration)}
                    </div>
                  </div>
                  <div className="pre-publish-block-info">
                    <div className="pre-publish-block-headline">
                      {" "}
                      Orientation:
                    </div>
                    <div className="pre-publish-block-description">
                      {videoType}
                    </div>
                  </div>
                  <div className="pre-publish-block-info">
                    <div className="pre-publish-block-headline">
                      {" "}
                      Dimensions:
                    </div>
                    <div className="pre-publish-block-description">
                      {dimensions}
                    </div>
                  </div>
                </div>
                <div className="upload-popup-form-hori-line"></div>
                <div className="upload-popup-form-body-right-title" id="tips">
                  Tips
                </div>
                <div
                  className="upload-popup-form-body-right-requirements"
                  id="tips"
                >
                  <img src={SuccessIcon} />
                  Try to keep your title under 50 characters.
                </div>
                <div
                  className="upload-popup-form-body-right-requirements"
                  id="tips"
                >
                  <img src={SuccessIcon} />
                  The description of your video...
                </div>
                <div
                  className="upload-popup-form-body-right-requirements"
                  id="tips"
                >
                  <img
                    src={SuccessIcon}
                    className="upload-popup-form-body-right-icon"
                  />
                  A video’s category describes the one thing you expect a viewer
                  to learn from your video.
                </div>
                <div
                  className="upload-popup-form-body-right-requirements"
                  id="example"
                >
                  For example:{" "}
                </div>
                <div
                  className="upload-popup-form-body-right-requirements"
                  id="example"
                >
                  Learn how to calculate ________. or{" "}
                </div>
                <div
                  className="upload-popup-form-body-right-requirements"
                  id="example"
                >
                  “Understand the significance of __________.”
                </div>
                <div className="upload-popup-form-hori-line"></div>
                <div className="upload-popup-form-body-right-video-guidelines">
                  To learn more, check out Edgur’s{" "}
                  <a href="#" className="upload-terms">
                    Video Guidelines.
                  </a>
                </div>
              </div>
            </div>
            <div className="upload-bottom-component">
              <div className="upload-bottom-component-right">
                <ButtonComponent
                  text="Publish"
                  level={readyToPublish}
                  icon={false}
                  handleClick={handlePublishClick}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="upload-popup-form-body">
            <div className="upload-popup-form-body-left">
              <div className="upload-popup-form-body-insert">
                <UploadFile
                  state={loaded}
                  handleVideoUpload={handleVideoUpload}
                  handleVideoDetails={handleVideoDetails}
                  imageType={videoType}
                />
              </div>

              <div className="upload-popup-form-body-terms">
                <div className="upload-popup-form-body-term">
                  By uploading your video to Edgur, you acknowledge and agree to
                  our{" "}
                  <a href="#" className="upload-terms">
                    {" "}
                    Terms of Service{" "}
                  </a>{" "}
                  and{" "}
                  <a href="#" className="upload-terms">
                    {" "}
                    Guidelines.{" "}
                  </a>
                </div>
                <div className="upload-popup-form-body-term">
                  Your content should not violate others’ copyright or privacy
                  rights.
                </div>
              </div>
            </div>

            <div className="upload-popup-form-body-right">
              <div className="upload-popup-form-body-right-title">
                Before uploading
              </div>
              <div className="upload-popup-form-body-right-requirements">
                <img src={SuccessIcon} />A good video teaches one simple concept
                that can be summarized with one learning objctive.
              </div>
              <div className="upload-popup-form-body-right-requirements">
                <img src={SuccessIcon} />
                Your video must be 3-minutes or less.
              </div>
              <div className="upload-popup-form-body-right-requirements">
                <img
                  src={SuccessIcon}
                  className="upload-popup-form-body-right-icon"
                />
                Don’t make specific mentions about previous videos. Edgur users
                will watch your video in a wide variety of contexts.
              </div>
            </div>
          </div>
        )}
        {showAlarmModal && (
          <AlertModal
            type="warning"
            title="Video not saved"
            message="Are you sure you want to leave this page without saving your video?"
            onContinue={() => setClosePopup(true)}
            onCancel={() => setShowAlarmModal(false)}
          />
        )}
      </div>
    </Popup>
  );
};

export default UploadPopUpForm;
