import React, { useRef } from "react";
import CloudUpload from "../../assets/cloud-upload.svg";
import SuccessfullyUpload from "../../assets/success-icon.svg";
import Spinner from "../../components/Spinner/Spinner";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

const UploadFile = ({
  state,
  handleVideoUpload,
  handleVideoDetails,
  imageType = "portrait",
}) => {
  /*
    part of Upload Form used for video uploading
    has 3 states: not loaded, loading, loaded
    handleVideoDetails: callback prop function called to return video details 
    getVideo: callback prop function called to upload video
    */

  const fileInputRef = useRef(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleClick = () => {
    state == "not loaded" && fileInputRef.current.click();
    state == "loaded" && handleVideoDetails();
  };

  const handleChange = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    if (files.length > 0) {
      handleVideoUpload(files[0]);
    }
  };

  const data = {
    "not loaded": {
      icon: CloudUpload,
      mainTitle: "Drag and drop video files to upload",
      title: "",
      button: (
        <ButtonComponent
          text="Select files"
          level="primary"
          size="medium"
          handleClick={handleClick}
          icon={false}
        />
      ),
    },
    loading: {
      mainTitle: "Uploading your video...",
      title: "Please wait while your video is being uploaded.",
      button: (
        <ButtonComponent
          text="Select files"
          level="disable"
          size="medium"
          handleClick={handleClick}
          icon={false}
        />
      ),
    },
    loaded: {
      icon: SuccessfullyUpload,
      mainTitle: "Uploaded video successfully",
      title: `Great! It looks like you’ve uploaded a ${imageType} video.`,
      additionalTitle: "Now it’s time to add some details...",
      button: (
        <ButtonComponent
          text="Add details"
          level="primary"
          size="medium"
          handleClick={handleClick}
          icon={false}
        />
      ),
    },
    publishing: {
      mainTitle: "Publishing your video...",
      title: "Please wait while your video is being published.",
      button: null,
    },
  };

  return (
    <div
      className="upload-file"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept=".mp4, .mov"
        style={{ display: "none" }}
        onChange={handleChange}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <div>
        {state === "loading" || state === "publishing" ? (
          <Spinner />
        ) : (
          <img
            src={data[state].icon}
            alt="Upload Icon"
            className="upload-file-icon"
          />
        )}
      </div>
      <div className="upload-file-text">
        <div className="upload-file-text-main-title">
          {data[state].mainTitle}
        </div>
        <div className="upload-file-text-title">{data[state].title}</div>
        {data[state]?.additionalTitle && (
          <div className="upload-file-text-title">
            {data[state].additionalTitle}
          </div>
        )}
      </div>
      <div className="upload-file-button">{data[state].button}</div>
      {state === "not loaded" && (
        <p className="upload-instructions">Maximum file size: 100 MB</p>
      )}
    </div>
  );
};

export default UploadFile;
