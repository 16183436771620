import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import FilterList from "../FilterList/FilterList";
import ListVideoComponent from "../ListVideoComponent/ListVideoComponent";
import Spinner from "../Spinner/Spinner";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";

const Favorites = () => {
  /*
  Creates a page for Favorite videos
  Alligned with latest Figma design with certain changes (May 24)
  */

  const [userCategories, setUserCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [loading, setLoading] = useState(true);
  const [favoriteVideos, setFavoriteVideos] = useState([]);
  const dispatch = useDispatch();

  const fetchUserCategories = () => {
    let data=[]
    for (const item of favoriteVideos) {
      const categoryList = item.categories
      const categoryNames = categoryList.map((category) => category.name);
      data=data.concat(categoryNames)
    }
    data = [...new Set(data)].sort()
    setUserCategories(["All", ...data])
  }

  const fetchUserFavorites = async () => {
    try {
      const response = await userService.getUserFavorites();
      setFavoriteVideos(response);
    } catch (error) {
      console.error("Error fetching user favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserFavorites();
  }, [dispatch]);

  useEffect(() => {
    fetchUserCategories()
  }, [favoriteVideos])

  const filterVideos = (category) => {
    return favoriteVideos.filter((video) => {
      return (
        video.categories &&
        video.categories.some(
          (videoCategory) => videoCategory.name === category,
        )
      );
    });
  };

  const handleClick = (category) => {
    setSelectedCategories([category]);
  };

  return (
    <div className="favorites">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 100px)",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <FilterList
            categories={userCategories}
            selectedCategories={selectedCategories}
            handleClick={handleClick}
          />
          <div className="favorites-lists">
            {userCategories.map((category, index) => {
              const videosToShow = filterVideos(category);
              return (
                videosToShow.length > 0 &&
                (selectedCategories.includes("All") ||
                  selectedCategories.includes(category)) && (
                  <ListVideoComponent
                    key={index}
                    items={videosToShow}
                    heading={category}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Favorites;
