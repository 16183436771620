import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserVideoData } from "../../actions/data";
import { videoService } from "../../services/video.service.ts";
import { useNavigate, useLocation } from "react-router-dom";
import AdBanner from "../../components/AdBanner/AdBanner";
import FilterList from "../../components/FilterList/FilterList";
import ListVideoComponent from "../../components/ListVideoComponent/ListVideoComponent";
import Spinner from "../../components/Spinner/Spinner";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";
import ItemCard from "../../components/ItemCard/ItemCard.js";

const SearchPage = ({ isSubscribed = true }) => {
  /*
  It's a page that will replace Landing Page once we have users subscribed to the platform on the database
  The page also handles search queries

  todo: add a check for user subscription status (it's currently a prop with default true value)
  todo: once endpoints are created, add 
  1. popular playlists
  2. staff picks
  3. explore your interests
   */

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [loading, setLoading] = useState(true);
  const [popularVideos, setPopularVideos] = useState([]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favoriteVideos = useSelector((state) => state.data.userVideoData);
  const [recentVideos, setRecentVideos] = useState([]);
  const [isSearchDone, setIsSearchDone] = useState(null);
  const [query, setQuery] = useState(null);
  const location = useLocation();
  const [videos, setVideos] = useState(null);
  const [videosSearched, setVideosSearched] = useState(null);
  const [videosSearchedFiltered, setVideosSearchedFiltered] = useState(null);

  useEffect(() => {
    getSearchQuery();
  }, [location.search]);

  useEffect(() => {
    if (query) {
      const fetchVideos = async () => {
        try {
          const response = await videoService.getVideos();

          setVideos(response);
        } catch (error) {
          console.error("Error fetching videos:", error);
        }
      };

      fetchVideos();
    }
  }, [query]);

  useEffect(() => {
    if (videos !== null) {
      const handleSearch = async (query) => {
        setVideosSearched(
          videos.filter((video) =>
            video.title.toLowerCase().includes(query.toLowerCase())
          )
        );
      };

      handleSearch(query);
    }
  }, [videos]);

  useEffect(() => {
    if (videosSearched !== null) {
      setVideosSearchedFiltered(filterVideos(videosSearched));
    }
  }, [videosSearched, selectedCategories]);

  const getSearchQuery = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get("query");
    if (searchQuery) {
      setIsSearchDone(true);
      setQuery(searchQuery);
    } else {
      setIsSearchDone(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await videoService.getCategories();
      const categoryNames = response.map((category) => category.name);
      setCategories(["All", ...categoryNames]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      const response = await userService.getUserFavorites();
      dispatch(setUserVideoData(response));
    } catch (error) {
      console.error("Error fetching user favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWatchHistory = async () => {
    try {
      const response = await userService.getWatchHistory();

      setRecentVideos(response.map((entry) => entry.video));
    } catch (error) {
      console.error("Error fetching user watch history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSearchQuery();
    getPopularVideos();
    fetchUserFavorites();
    fetchWatchHistory();
    fetchCategories();
  }, []);

  const getPopularVideos = async () => {
    const response = await videoService.getPopular(20);
    setPopularVideos(response);
  };

  const handleClick = (category) => {
    setSelectedCategories([category]);
  };

  const findSearchPageHeadline = () => {
    if (selectedCategories.includes("All")) {
      return "All ";
    } else if (selectedCategories.length === 1) {
      return `${selectedCategories[0]} `;
    } else {
      return "Multiple ";
    }
  };

  const filterVideos = (videos) => {
    if (!videos) {
      return [];
    }

    if (selectedCategories.includes("All")) {
      return videos;
    }

    return videos.filter((video) =>
      video.categories.some((category) =>
        selectedCategories.includes(category.name)
      )
    );
  };

  if (loading && isSearchDone === null) {
    return <Spinner />;
  }

  const isSubscribedComponent = (
    <>
      {filterVideos(recentVideos).length > 0 && (
        <ListVideoComponent
          items={filterVideos(recentVideos)}
          heading="Continue Watching"
        />
      )}
      {filterVideos(favoriteVideos).length > 0 && (
        <ListVideoComponent
          items={filterVideos(favoriteVideos)}
          heading="Your Favorites"
        />
      )}
      {filterVideos(popularVideos).length > 0 && (
        <ListVideoComponent
          items={filterVideos(popularVideos)}
          heading="Popular in Videos"
        />
      )}
      {/* Add popular playlists here */}
      {/* Add staff picks here */}
      {/* Add Explore Your Interests here */}
    </>
  );

  const isSearchComponent = (
    <div className="search-page">
      {/* The headline according to out design{findSearchPageHeadline()} */}
      <div className="search-page-header">Search Results</div>
      {videosSearchedFiltered && (
        <div
          className={
            videosSearchedFiltered.length * 336 > window.innerWidth
              ? `search-page-videos`
              : "search-page-one-row"
          }
        >
          {videosSearchedFiltered.map((video) => (
            <div className="search-page-video" key={video.id}>
              <ItemCard object={video} is_horizontal={true} level="large"/>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="landing-page">
      <FilterList
        categories={categories}
        selectedCategories={selectedCategories}
        handleClick={handleClick}
      />
      {!isSubscribed && (
        <>
          {!isAuthenticated ? (
            <div className="ad-banner-signup">
              <AdBanner
                isLogin={false}
                handleClick={() => navigate("/signup")}
              />
            </div>
          ) : (
            <div className="ad-banner-login">
              <div className="add-banner-login-video">
                {popularVideos.length > 0 && (
                    <ItemCard object={popularVideos[0]} is_banner={true} is_horizontal={true} level="none"/>
                )}
              </div>

              <div className="add-banner-login-banner">
                <AdBanner
                  isLogin={true}
                  handleClick={() => navigate("/not-created-yet")}
                />
              </div>
            </div>
          )}
          <ListVideoComponent
            items={filterVideos(popularVideos)}
            heading="Popular"
          />{" "}
        </>
      )}
      {isSubscribed && !isSearchDone && isSubscribedComponent}
      {isSubscribed && isSearchDone && isSearchComponent}
    </div>
  );
};

export default SearchPage;
