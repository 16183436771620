import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserVideoData } from "../../actions/data";
import { videoService } from "../../services/video.service.ts";
import { useNavigate } from "react-router-dom";
import AdBanner from "../../components/AdBanner/AdBanner";
import FilterList from "../../components/FilterList/FilterList";
import ListVideoComponent from "../../components/ListVideoComponent/ListVideoComponent";
import Spinner from "../../components/Spinner/Spinner";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";
import ItemCard from "../../components/ItemCard/ItemCard.js";

const LandingPage = ({ isSubscribed = true }) => {
  /*
  It's a page that will replace Landing Page once we have users subscribed to the platform on the database
  todo: add a check for user subscription status (it's currently a prop with default true value)
  todo: once endpoints are created, add 
  1. popular playlists
  2. staff picks
  3. explore your interests
   */

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [loading, setLoading] = useState(true);
  const [popularVideos, setPopularVideos] = useState([]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favoriteVideos = useSelector((state) => state.data.userVideoData);
  const [recentVideos, setRecentVideos] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await videoService.getCategories();
      const categoryNames = response.map((category) => category.name);
      setCategories(["All", ...categoryNames]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      const response = await userService.getUserFavorites();
      dispatch(setUserVideoData(response));
    } catch (error) {
      console.error("Error fetching user favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWatchHistory = async () => {
    try {
      const response = await userService.getWatchHistory();

      setRecentVideos(response.map((entry) => entry.video));
    } catch (error) {
      console.error("Error fetching user watch history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPopularVideos();
    fetchUserFavorites();
    fetchWatchHistory();
    fetchCategories();
  }, []);

  const getPopularVideos = async () => {
    const response = await videoService.getPopular(20);
    setPopularVideos(response);
  };

  const handleClick = (category) => {
    setSelectedCategories([category]);
  };

  const filterVideos = (videos) => {
    if (!videos) {
      return [];
    }

    if (selectedCategories.includes("All")) {
      return videos;
    }

    return videos.filter((video) =>
      video.categories.some((category) =>
        selectedCategories.includes(category.name)
      )
    );
  };

  if (loading) {
    return <Spinner />;
  }

  const isSubscribedComponent = (
    <>
      {filterVideos(recentVideos).length > 0 && (
        <ListVideoComponent
          items={filterVideos(recentVideos)}
          heading="Continue Watching"
        />
      )}
      {filterVideos(favoriteVideos).length > 0 && (
        <ListVideoComponent
          items={filterVideos(favoriteVideos)}
          heading="Your Favorites"
        />
      )}
      {filterVideos(popularVideos).length > 0 && (
        <ListVideoComponent
          items={filterVideos(popularVideos)}
          heading="Popular in Videos"
        />
      )}
      {/* Add popular playlists here */}
      {/* Add staff picks here */}
      {/* Add Explore Your Interests here */}
    </>
  );

  return (
    <div className="landing-page">
      <FilterList
        categories={categories}
        selectedCategories={selectedCategories}
        handleClick={handleClick}
      />
      {!isSubscribed && (
        <>
          {!isAuthenticated ? (
            <div className="ad-banner-signup">
              <AdBanner
                isLogin={false}
                handleClick={() => navigate("/signup")}
              />
            </div>
          ) : (
            <div className="ad-banner-login">
              <div className="add-banner-login-video">
                {popularVideos.length > 0 && (
                  <ItemCard object={popularVideos[0]} is_banner={true} is_horizontal={true} level="none"/>
                )}
              </div>

              <div className="add-banner-login-banner">
                <AdBanner
                  isLogin={true}
                  handleClick={() => navigate("/not-created-yet")}
                />
              </div>
            </div>
          )}
          <ListVideoComponent
            items={filterVideos(popularVideos)}
            heading="Popular"
          />{" "}
        </>
      )}
      {isSubscribed && isSubscribedComponent}
    </div>
  );
};

export default LandingPage;
