import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import authService from "../../services/auth.service.ts";
import {
  AUTHENTICATED_SUCCESS,
  USER_LOADED_FAIL,
  USER_LOADED_SUCCESS,
} from "../../actions/types.js";
import { useDispatch } from "react-redux";
import googleLogo from "../../assets/google-logo.svg";
import githubLogo from "../../assets/github-logo.svg";
import chevronRight from "../../assets/chevron-right.svg";
import edgurLogo from "../../assets/edgur-logo-text.svg";
import "./styles.scss";

const SocialRedirectPage = () => {
  /*
    Page for social authentication. Currently, Google and Github.
  */
  const [params] = useSearchParams();
  const code = params.get("code");
  const connection = params.get("connection");
  const next = params.get("next");
  const [error, setError] = useState("");
  const [message, setMessage] = useState(<p>Authenticating...</p>);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const requestLoadUser = async () => {
    try {
      const response = await authService.loadUser();
      if (response) {
        dispatch({
          type: USER_LOADED_SUCCESS,
          payload: response,
        });
      }
    } catch (e) {
      console.log("User load error: ", e);
      dispatch({ type: USER_LOADED_FAIL });
    }
  };

  useEffect(() => {
    const social_login = async () => {
      if (code && connection) {
        try {
          await authService.social_login(code, connection).then((response) => {
            if (response) {
              setMessage(
                <p>
                  Authentication successful! Taking you back to <b>edgur</b>...
                </p>,
              );
              dispatch({
                type: AUTHENTICATED_SUCCESS,
              });
              requestLoadUser();
              navigate(next || "/");
            }
          });
        } catch (e) {
          if (e?.response?.data?.detail?.error_description) {
            setError("Error: " + e?.response?.data?.detail?.error_description);
          } else {
            setError("Something went wrong. Please try again later.");
          }
        }
      }
    };
    social_login();
  }, []);

  return (
    <div className="social-container">
      {error ? (
        <>
          <div className="logo-container">
            <img src={edgurLogo} alt="edgur" width={120} height={120} />
          </div>
          <p>{error}</p>
          <a href="/">Return to Home</a>
        </>
      ) : code && connection ? (
        <>
          <div className="logo-container">
            <img
              src={connection === "google-oauth2" ? googleLogo : githubLogo}
              alt="logo"
              width={50}
              height={50}
            />
            <img src={chevronRight} alt="chevron" width={20} height={20} />
            <img src={edgurLogo} alt="edgur" width={70} height={70} />
          </div>
          {message}
        </>
      ) : (
        <>
          <div className="logo-container">
            <img src={edgurLogo} alt="edgur" width={120} height={120} />
          </div>
          <p>
            {`Authentication failed. No ${code ? "connection provided" : "code returned"}. Please try again.`}
          </p>
          <Link to="/">Return to Home</Link>
        </>
      )}
    </div>
  );
};

export default SocialRedirectPage;
