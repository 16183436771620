import React, { useState, useEffect } from "react";
import infoIcon from "../../assets/info.svg";
import "./styles.scss";

const TextAreaComponent = ({
  label,
  icon = true,
  handleInput,
  placeholder = "Write a description",
  initialInput = "",
  limit = 500,
}) => {
  /*
    getting state as a prop only matter if it's one of the following: disable, invalid, validated, helper
    Also, note, that it's not finished component. Make sure that it works for your inputs
    Alligned with Figma desing as of 15th of May
    handleInput: callback prop function that handles input change
    */

  const [charCount, setCharCount] = useState(initialInput?.length || 0);
  const [inputValue, setInputValue] = useState(initialInput);

  const handleTextAreaChange = (e) => {
    if (e.target.value.length <= limit) {
      handleInput(e);
      setCharCount(e.target.value.length);
      setInputValue(e.target.value);
    }
  };

  useEffect(() => {
    setInputValue(initialInput);
  }, [initialInput]);

  return (
    <div className="text-area-component">
      {label && (
        <div className="text-area-label">
          {label}
          {icon ? <img className="text-area-label-img" src={infoIcon} /> : null}
        </div>
      )}
      <div className="text-area-component-bottom">
        <textarea
          onChange={handleTextAreaChange}
          className="text-area-component-input"
          placeholder={placeholder}
          value={inputValue}
        />
        <div className="char-count">
          {charCount}/{limit}
        </div>
      </div>
    </div>
  );
};

export default TextAreaComponent;
