import React from "react";
import "./styles.scss";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

export const renderMixedText = ({ text }) => {
  const parts = text?.split(/(\$[^$]*\$)/g);

  return (
    <span>
      {parts?.map((part, index) => {
        if (part.startsWith("$") && part.endsWith("$") && part !== "$") {
          const latexContent = part.slice(1, -1);
          return <InlineMath key={index} math={latexContent} />;
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    </span>
  );
};

// Counts length of text inside RichTextEditor
export const countChar = (node) => {
  if (!node) return 0;
  let totalLength = 0;

  if (
    node.type === "doc" ||
    node.type === "paragraph" ||
    node.type === "bulletList" ||
    node.type === "orderedList" ||
    node.type === "listItem" ||
    node.type === "codeBlock" ||
    node.type === "blockquote"
  ) {
    node.content?.forEach((child) => {
      totalLength += countChar(child);
    });
  }
  if (node.type === "text") {
    totalLength += node.text.length;
  }
  return totalLength;
};

// Converts json in RichTextEditor into parsable text for other components
export const renderContent = (node, key = "root") => {
  if (!node) return null;

  if (node.type === "doc") {
    return node.content?.map((child, idx) =>
      renderContent(child, `doc-${idx}`),
    );
  }

  if (node.type === "paragraph") {
    return (
      <p key={key}>
        {node.content?.map((child, idx) =>
          renderContent(child, `paragraph-${idx}`),
        )}
      </p>
    );
  }

  if (node.type === "text") {
    let text = node.text;
    if (node.marks) {
      node.marks.forEach((mark, idx) => {
        if (mark.type === "bold") {
          text = <strong key={`bold-${key}-${idx}`}>{text}</strong>;
        } else if (mark.type === "italic") {
          text = <em key={`italic-${key}-${idx}`}>{text}</em>;
        } else if (mark.type === "link") {
          text = (
            <a
              href={mark.attrs.href}
              key={`link-${key}-${idx}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          );
        } else if (mark.type === "code") {
          text = (
            <code key={`code-${key}-${idx}`} className="inline-code">
              {text}
            </code>
          );
        } else {
          text = renderMixedText({ text });
        }
      });
    } else {
      text = renderMixedText({ text });
    }
    return text;
  }

  if (node.type === "bulletList") {
    return (
      <ul key={key}>
        {node.content?.map((child, idx) =>
          renderContent(child, `bulletList-${idx}`),
        )}
      </ul>
    );
  }

  if (node.type === "orderedList") {
    return (
      <ol key={key}>
        {node.content?.map((child, idx) =>
          renderContent(child, `orderedList-${idx}`),
        )}
      </ol>
    );
  }

  if (node.type === "listItem") {
    return (
      <li key={key}>
        {node.content?.map((child, idx) =>
          renderContent(child, `listItem-${idx}`),
        )}
      </li>
    );
  }

  if (node.type === "codeBlock") {
    return (
      <pre key={key} className="code-block">
        <code>
          {node.content?.map((child, idx) =>
            renderContent(child, `codeBlock-${idx}`),
          )}
        </code>
      </pre>
    );
  }

  if (node.type === "blockquote") {
    return (
      <div key={key} className="blockquote">
        {node.content?.map((child, idx) =>
          renderContent(child, `blockquote-${idx}`),
        )}
      </div>
    );
  }

  return null;
};

const extractText = (node) => {
  // If the node has text, return it
  if (node.text) {
    return node.text;
  }

  // If the node has content, recursively extract text from child nodes
  if (node.content && Array.isArray(node.content)) {
    return node.content.map(extractText).join("");
  }

  // Return an empty string if no text or content is found
  return "";
};

//Check if RichText is empty (no text or only spaces)
export const isRichTextEmpty = (richTextJSON) => {
  if (!richTextJSON || !Array.isArray(richTextJSON.content)) {
    return true;
  }

  // Extract text from all nodes
  const textContent = richTextJSON.content.map(extractText).join("");

  // Check if the extracted text is empty
  return textContent.trim() === "";
};
