import React, { useRef, useEffect } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import CrossSign from "../../assets/cross-sign.svg";
import "./styles.scss";
import { hideModal, showModal } from "../../actions/global.action";
import { useDispatch } from "react-redux";

const ShortcutsModal = ({ onClose }) => {
  /* 
    Modal with shortcuts. Available via clicking on Avatar in Navbar > Shortcuts.
  */

  const dispatch = useDispatch();
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => {
    onClose();
  });

  useEffect(() => {
    dispatch(showModal());
    return () => {
      dispatch(hideModal());
    };
  }, [dispatch]);

  return (
    <div className="shortcuts-modal-container">
      <div className="shortcuts-modal" ref={modalRef}>
        <div className="close-modal">
          <div className="shortcuts-modal-header">Keyboard Shortcuts</div>
          <button className="video-add-cross-sign" onClick={() => onClose()}>
            <img src={CrossSign} alt="X" />
          </button>
        </div>
        <div className="shortcuts-modal-content">
          <div className="column">
            <span className="header">Feed</span>
            <div className="shortcut">
              <span className="shortcut-name">Previous item</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">↑</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">Next item</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">↓</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">See notes</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">N</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">Open/Close detail mode</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">.</span>
              </div>
            </div>
          </div>
          <div className="column">
            <span className="header">Video</span>
            <div className="shortcut">
              <span className="shortcut-name">Rewind for 5 seconds</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">←</span> or
                <span className="shortcut-button">J</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">Pause/Play</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button wider">Spacebar</span> or
                <span className="shortcut-button">K</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">Fast forward for 5 seconds</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">→</span> or
                <span className="shortcut-button">L</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">Open/Close full screen mode</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">F</span>
              </div>
            </div>
            <div className="shortcut">
              <span className="shortcut-name">Mute</span>
              <div className="shortcut-button-container">
                <span className="shortcut-button">M</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortcutsModal;
