import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyCard from "./MyCard";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Spinner from "../Spinner/Spinner";
import UploadAssCard from "../../modals/UploadAssCard/UploadAssCard";
import { fetchCards } from "../../actions/card";
import "./styles.scss";

const MyCards = () => {
  const dispatch = useDispatch();
  const [showUpload, setShowUpload] = useState(false);

  const { cards, loading } = useSelector((state) => state.card);

  useEffect(() => {
    if (cards.length === 0) {
      dispatch(fetchCards());
    }
  }, [dispatch, cards.length]);

  const handleChange = () => {
    dispatch(fetchCards());
  };

  const cardItems = cards
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((card) => (
      <MyCard key={card.id} card={card} handleChange={handleChange} />
    ));

  return (
    <div className="my-content">
      <div className="initial-heading">
        <div>Cards</div>
        <div className="initial-heading-button">
          <ButtonComponent
            text="Add Cards"
            icon={true}
            uploadIcon={true}
            handleClick={() => setShowUpload(true)}
          />
        </div>
        {showUpload && (
          <UploadAssCard handleClose={() => setShowUpload(false)} />
        )}
      </div>
      <div className="table-component">
        <div className="table-headings-component">
          <div className="first-child card-styling">Card Information</div>
          <div className="second-child">Stats</div>
          <div className="third-child">Visibility Status</div>
          <div className="fourth-child">Controls</div>
        </div>
        <div className="video-items">
          {loading ? (
            <div className="loading-container-styling">
              <Spinner />
            </div>
          ) : (
            <>{cardItems}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCards;
