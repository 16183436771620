import React, { useEffect, useRef } from "react";
import "./styles.scss";
import useOutsideClick from "../../hooks/useOutsideClick";

const AnimatedDropdown = ({
  key,
  className,
  children,
  closeDropdown,
  closeFunc,
  style,
}) => {
  /*
    Dropdown animation
  */
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => handleCloseDropdown());

  const handleCloseDropdown = () => {
    modalRef.current.classList.add("animate__close-dropdown");
    setTimeout(() => {
      closeFunc();
    }, 200);
  };

  useEffect(() => {
    if (closeDropdown) {
      handleCloseDropdown();
    }
  }, [closeDropdown]);

  return (
    <div
      key={key}
      className={`${className} animated-dropdown`}
      style={style}
      ref={modalRef}
    >
      {children}
    </div>
  );
};

export default AnimatedDropdown;
