import React from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import "./styles.scss";

const AdBanner = ({isLogin = false, handleClick}) => {
  /* AdBanner component used on the landing page
  Alligned with the latest Figma design (May 24)
  handleClick: callback prop to be called when the button is clicked
  isLogin: boolean prop to determine if the ad is for authenticated users or not
   */

  return (
    <div className="ad-banner-container">
      <div className={`ad-banner ${isLogin? "login" : ""}`}>
        <div className="ad-content">
          <span className="ad-title">Start A 30-Day Free Trial Today!</span>
          <p className="ad-description">
            An online video library that makes it easy to organize, collaborate,
            and share? Out of this world.
          </p>
          <ButtonComponent text={isLogin? "Start now" : "Sign up"} handleClick={handleClick} level="secondary" icon={false} noBorders={true}/>
        </div>
        {!isLogin? <img className="ad-img" src="./ad-icon.png" alt="ad-icon" /> : null}
      </div>
    </div>
  );
};

export default AdBanner;