export const checkIsDuplicatePlaylist = (
  title: string,
  list: any[],
): boolean => {
  return list.some((playlistItem: any) => playlistItem.title === title);
};

const usePlaylist = () => {};

export default usePlaylist;
