import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { showFooter } from "../utils/constants.js";
import CookieFooter from "../components/Footer/CookieFooter.js";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const ShowNavbar =
    location.pathname !== "/login" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/social-auth";

  return (
    <>
      {ShowNavbar && <Navbar />}
      <div className="main">{children}</div>
      {isAuthenticated === false && showFooter && <Footer />}
      {isAuthenticated && <CookieFooter />}
    </>
  );
};

export default Layout;
