const initialState = {
  currentTime: 0,
  playerRef: null,
  playerRefs: {},
  currentVideo: null,
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_TIME":
      state.playerRef.currentTime(action.payload);
      return { ...state, currentTime: action.payload };
    case "SET_VIDEO_PLAYER_REF":
      return { ...state, playerRef: action.payload };
    case "SET_VIDEO_PLAYER_REFS":
      return {
        ...state,
        playerRefs: { ...state.playerRefs, ...action.payload },
      };
    case "SET_CURRENT_VIDEO":
      return { ...state, currentVideo: action.payload };
    case "ADD_VIDEO_LIKE":
      return {
        ...state,
        currentVideo: {
          ...state.currentVideo,
          is_liked: true,
          likes_count: state.currentVideo.likes_count + 1,
        },
      };
    case "REMOVE_VIDEO_LIKE":
      return {
        ...state,
        currentVideo: {
          ...state.currentVideo,
          is_liked: false,
          likes_count: Math.max(state.currentVideo.likes_count - 1, 0),
        },
      };
    default:
      return state;
  }
};

export default videoReducer;
