import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { reset_password_confirm } from '../actions/auth';
import authService from "../services/auth.service.ts";
import {
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
} from "../actions/types";

const ResetPasswordConfirm = () => {
  const dispatch = useDispatch();
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const { new_password, re_new_password } = formData;

  const requestConfirmResetPassword = async (data) => {
    try {
      const response = await authService.confirmResetPassword(data);
      setRequestSent(true);
      dispatch({ type: PASSWORD_RESET_CONFIRM_SUCCESS });
    } catch (e) {
      dispatch({ type: PASSWORD_RESET_CONFIRM_FAIL });
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    // const uid = match.params.uid;
    // const token = match.params.token;
    const uid = "";

    const data = { uid, new_password, re_new_password };
    requestConfirmResetPassword(data);
    // reset_password_confirm(uid, token, new_password, re_new_password);
  };

  if (requestSent) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container mt-5">
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            placeholder="New Password"
            name="new_password"
            value={new_password}
            onChange={(e) => onChange(e)}
            minLength="6"
            required
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            placeholder="Confirm New Password"
            name="re_new_password"
            value={re_new_password}
            onChange={(e) => onChange(e)}
            minLength="6"
            required
          />
        </div>
        <button className="btn btn-primary" type="submit">
          Reset Password
        </button>
      </form>
    </div>
  );
};

// export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
export default ResetPasswordConfirm;
