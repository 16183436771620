import React, { useEffect } from "react";
import { clearToastMessage } from "../../actions/global.action";
import { useDispatch, useSelector } from "react-redux";
import success from "../../assets/success-icon.svg";
import error from "../../assets/error.svg";
import warning from "../../assets/warning.svg";
import "./styles.scss";

const Toast = () => {
  /*
  Toast notification form
  Alliged with the latest Figma design (May 15)
  */

  const dispatch = useDispatch();
  const { toastMessage, type } = useSelector((state) => state.global);
  let timeout;
  if (toastMessage.length > 150) {
    timeout = 7000;
  } else if (toastMessage.length < 70) {
    timeout = 3000;
  } else {
    timeout = 45 * toastMessage.length;
  }

  useEffect(() => {
    let timeoutId;
    if (toastMessage) {
      timeoutId = setTimeout(() => {
        dispatch(clearToastMessage());
      }, timeout);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [toastMessage]);

  return (
    <>
      {toastMessage && (
        <div
          className="toast-container"
          style={{
            WebkitAnimation: `slide-fadein 0.5s, slide-fadeout 0.5s ${timeout / 1000 - 0.5}s`,
            animation: `slide-fadein 0.5s, slide-fadeout 0.5s ${timeout / 1000 - 0.5}s`,
          }}
        >
          <div className="toast-img">
            <img
              src={
                type === "success"
                  ? success
                  : type === "error"
                    ? error
                    : warning
              }
              alt="icon"
            />
          </div>
          <div>{toastMessage}</div>
        </div>
      )}
    </>
  );
};

export default Toast;
