import { useEffect } from "react";

export function useCardObservers(cardRefs, setCardIndex) {
  useEffect(() => {
    const observers = cardRefs.current.map((ref, index) => {
      if (!ref.current) return undefined;

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
            setCardIndex(index + 1);
          }
        },
        { threshold: [0.5] },
      );

      observer.observe(ref.current);
      return observer;
    });

    return () =>
      observers.forEach((observer) => observer && observer.disconnect());
  }, [cardRefs, setCardIndex]);
}
