import videojs from "video.js";

const Button = videojs.getComponent("Button");

class AutoplayButton extends Button {
  constructor(player, options) {
    super(player, options);
    this.controlText("Autoplay");
    this.player = player;
    this.onAutoplayChange = options.onAutoplayChange;
    this.on("click", this.handleClick.bind(this));
    this.lastClickTime = 0;
    this.debouncePeriod = 300;
    this.updateInterval = setInterval(() => this.updateState(), 1000);
  }

  createEl() {
    let button = super.createEl("button", {
      className: "vjs-autoplay-button vjs-control vjs-button",
    });

    let toggleContainer = videojs.dom.createEl("div", {
      className: "autoplay-toggle",
      innerHTML: `<div class="autoplay-slider"><div class="autoplay-icon"></div></div>`,
    });

    button.appendChild(toggleContainer);
    return button;
  }

  handleClick() {
    const currentTime = Date.now();
    if (currentTime - this.lastClickTime < this.debouncePeriod) {
      return;
    }
    this.lastClickTime = currentTime;
    const new_state = !this.player.autoplay();
    this.player.autoplay(new_state);
    this.onAutoplayChange(new_state);
    this.updateState();
  }

  updateState() {
    try {
      let toggleContainer = this.el().querySelector(".autoplay-toggle");
      let slider = this.el().querySelector(".autoplay-slider");
      let icon = this.el().querySelector(".autoplay-icon");
      if (!slider || !icon) {
        throw new Error("Slider or Icon element not found");
      }
      const temp = this.player.autoplay();
      toggleContainer.className = temp
        ? "autoplay-toggle on"
        : "autoplay-toggle";
      slider.style.transform = temp ? "translateX(0px)" : "translateX(-17px)";
      slider.style.backgroundColor = temp ? "#35a7ff" : "#ffffff";
      icon.className = temp ? "autoplay-icon play" : "autoplay-icon pause";
    } catch (error) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  dispose() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
    super.dispose();
  }
}

videojs.registerComponent("AutoplayButton", AutoplayButton);

export default AutoplayButton;
