import { Cookie } from "../utils/cookie.ts";
import { AUTH_TOKEN } from "../utils/enum.ts";
import { jwtDecode } from "jwt-decode";

export function RequestInterceptorResolve(config: any) {

  // Skip token check for refresh token endpoint
  if (config.url?.includes('/auth/token/refresh/')) {
    console.log("Intercepted refresh call to allow refreshing access token")
    return config;
  }

  const token = Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN) || localStorage.getItem("access");

  // Check if token is not expired: does not check if token is valid
  // Any well-formed JWT can be decoded @https://www.npmjs.com/package/jwt-decode
  // Validating token must be done server-side with secret/public key
  if (token) {
    const decoded = jwtDecode(token);
    if (!decoded.exp || decoded.exp < Date.now() / 1000) {
      Cookie.Remove(AUTH_TOKEN.ACCESS_TOKEN);
      localStorage.removeItem("access");
    } else {
      config.headers!["Authorization"] = `JWT ${token}`;
    }
  }
  return config;
}

export function RequestInterceptorReject(error: any) {
  return Promise.reject(error);
}
