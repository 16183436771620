import React, {useState, useEffect} from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import "./styles.scss";

const CookieFooter = () => {
    /*
        Bottom banner for storing user's agreeement to cookies when authenticated.
    */

    const [consent, setConsent] = useState(false);

    useEffect(() => {
        // Check if consent has already been given
        const consentGiven = localStorage.getItem('cookieConsent');
        if (consentGiven) {
        setConsent(true);
        }
    }, []);

    const handleConsent = () => {
        // Set consent in localStorage
        localStorage.setItem('cookieConsent', 'true');
        setConsent(true);
    };

    if (consent) {
        return null; // Don't show the consent banner if consent is already given
    }

  return (
    <div className="cookie-banner">
      <span className="banner-text">
        We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking “Accept,” you agree to our website's cookie use as described in our {" "}
        <a href="/pages/privacy" className="upload-terms" target="_blank" rel="noopener noreferrer">
            Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/pages/terms" className="upload-terms" target="_blank" rel="noopener noreferrer">
            Terms and Conditions.
            </a>
      </span>
      <div className="cookie-button">
        <ButtonComponent
          text="Accept"
          level="primary"
          size="medium"
          icon={false}
          noBorders={true}
          handleClick={() => {
              handleConsent();
          }}
          coverFullWidth={true}
        />
      </div>
    </div>
  );
};

export default CookieFooter;
