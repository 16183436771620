import {
  ADD_LIKE,
  REMOVE_LIKE,
  SET_DATA,
  SET_USER_VIDEO_DATA,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UPDATE_NOTE_CONTENT,
} from "./types";

export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});

export const setUserVideoData = (data) => ({
  type: SET_USER_VIDEO_DATA,
  payload: data,
});

export const updateNoteContent = (id, note) => {
  return {
    type: UPDATE_NOTE_CONTENT,
    payload: { id, note },
  };
};

export const addLike = (id) => {
  return {
    type: ADD_LIKE,
    payload: id,
  };
};

export const removeLike = (id) => {
  return {
    type: REMOVE_LIKE,
    payload: id,
  };
};

export const subscribeEvent = (id) => {
  return {
    type: SUBSCRIBE,
    payload: id,
  };
};

export const unsubscribeEvent = (id) => {
  return {
    type: UNSUBSCRIBE,
    payload: id,
  };
};
