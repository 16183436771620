import React, { useState, useEffect } from "react";
import TextInputComponent from "../../components/TextInputComponent/TextInputComponent";
import MultipleComponent from "../../components/MultipleComponent/MultipleComponent";
import DropdownMenuPrivacy from "../../components/DropdownMenuPrivacy/DropdownMenuPrivacy";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action";
import arrowLeft from "../../assets/arrow-left.svg";
import { parseViews } from "../../utils/video";
import "./styles.scss";
import RichTextEditorComponent from "../../components/RichTextEditor/RichTextEditorComponent";
import { countChar } from "../../utils/editor.js";
import { userService } from "../../services/user.service.ts";
import { videoService } from "../../services/video.service.ts";
import NotFoundPage from "../NotFoundPage/NotFoundPage.js";
import ThumbnailChangeComponent from "../../components/ThumbnailChangeComponent/ThumbnailChangeComponent.js";
import Spinner from "../../components/Spinner/Spinner.js";

const EditVideo = () => {
  /*
    Page to edit properties of the video. Accessible via Dashboard > Videos > Edit button.
  */

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [visibility, setVisibility] = useState("");
  const [readyToPublish, setReadyToPublish] = useState("disable");
  const [video, setVideo] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [videoType, setVideoType] = useState("landscape");
  const [img, setImg] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailSec, setThumbnailSec] = useState(null);
  const [saving, setSaving] = useState(false);
  const location = useLocation();
  const videoId = location.pathname.slice(12);

  const fetchVideos = async () => {
    try {
      setNotFound(null);
      const response = await userService.getUserVideos();

      let videoFile = null;
      for (const file of response) {
        if (file.id === videoId) {
          videoFile = file;
        }
      }

      if (!videoFile) {
        setNotFound("video");
        return;
      }

      setVideo(videoFile);
      setVisibility(videoFile.visibility);
      setTitle(videoFile.title);
      let oldDesc = videoFile.description;
      try {
        oldDesc = JSON.parse(videoFile.description);
        setDescription(oldDesc);
      } catch (err) {
        oldDesc = {
          type: "doc",
          content: [
            { type: "paragraph", content: [{ type: "text", text: oldDesc }] },
          ],
        };
      }
      setDescription(oldDesc);
      setTags(videoFile.tags.map((tag) => tag.name));
      setCategories(videoFile.categories.map((category) => category.name));
      setNewTags(videoFile.tags.map((tag) => tag.name));
      setNewCategories(videoFile.categories.map((category) => category.name));
      setVideoType(videoFile.video_type);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [videoId]);

  const closePage = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (title && description && newTags.length && newCategories.length) {
      setReadyToPublish("primary");
    } else {
      setReadyToPublish("disable");
    }
  }, [title, description, newTags, newCategories]);

  const createTagOutput = () => {
    let output = "";
    for (let tag of tags) {
      output += "#" + tag + " ";
    }
    output.slice(0, output.length - 1);
    return output;
  };

  const handleInputTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleInputLearningObjective = (categoriess) => {
    setNewCategories(categoriess);
  };

  const handleTagChange = (tags) => {
    setNewTags(tags);
  };

  const handleVisibilityChange = (selectedOption) => {
    setVisibility(selectedOption);
  };

  const handlePublishClick = async () => {
    if (readyToPublish === "disable") {
      return;
    }
    const formData = new FormData();
    if (title.length > 100) {
      dispatch(notifyError("Title should be no longer than 100 characters!"));
      return;
    }
    const currDescLength = countChar(description);
    if (currDescLength > 500) {
      dispatch(
        notifyError("Description should be no longer than 500 characters!"),
      );
      return;
    }
    setSaving(true);
    const formattedDescription = JSON.stringify(description);
    formData.append("title", title);
    formData.append("description", formattedDescription);
    formData.append("tags", newTags);
    formData.append("categories", newCategories);
    formData.append("visibility", visibility);

    if (thumbnail) {
      try {
        await videoService.editVideoThumbnail(videoId, {
          thumbnail: thumbnail,
        });
      } catch (error) {
        setSaving(false);
        dispatch(notifyError("Video thumbnail save failed"));
        console.error("Error:", error);
        return;
      }
    } else if (thumbnailSec) {
      try {
        await videoService.editVideoThumbnail(videoId, {
          timecode: thumbnailSec,
        });
      } catch (error) {
        setSaving(false);
        dispatch(notifyError("Video thumbnail save failed"));
        console.error("Error:", error);
        return;
      }
    }
    try {
      await videoService.editVideo(videoId, formData);
      dispatch(notifySuccess("Video saved successfully!"));
    } catch (error) {
      setSaving(false);
      dispatch(notifyError("Video save failed"));
      console.error("Error:", error);
    }
    setSaving(false);
    navigate("/dashboard");
  };

  function formatTitle(title) {
    if (title.length > 55) {
      let trimmed = title.substring(0, 55);
      let lastSpace = trimmed.lastIndexOf(" ");
      if (lastSpace > 0) {
        return title.substring(0, lastSpace) + "...";
      }
      return title.substring(0, 55) + "...";
    }

    return title;
  }

  return notFound ? (
    <NotFoundPage pageType={notFound} />
  ) : (
    video && (
      <div className="edit-video-page">
        <div className="upload-popup-form-body">
          <div className="upload-popup-form-body-left">
            <div className="upload-popup-form-body-header">
              <img src={arrowLeft} onClick={closePage}></img>
              <div>Video Details</div>
            </div>
            <div className="upload-popup-form-preview">
              <div className="upload-popup-form-preview-left">
                <ThumbnailChangeComponent
                  video={video}
                  img={img}
                  setImg={setImg}
                  setThumbnail={setThumbnail}
                  redirect={true}
                  setThumbnailSec={setThumbnailSec}
                />
              </div>
              <div className="upload-popup-form-preview-right">
                <div
                  className={`upload-popup-form-preview-right-title ${title ? "has-title" : ""}`}
                >
                  {title ? formatTitle(title) : "Untitled"}
                </div>
                <div
                  className={`upload-popup-form-preview-right-tag ${tags.length ? "has-tag" : ""}`}
                >
                  {tags.length ? createTagOutput() : "#Tag"}
                </div>
                <div className="upload-popup-form-preview-right-divider"></div>
                <div className="upload-popup-form-preview-right-visibility">
                  <div className="upload-popup-form-preview-right-visibility-word">
                    Visibility
                  </div>
                  <DropdownMenuPrivacy
                    category="privacy"
                    currentVisibility={video.visibility}
                    handleVisibilityChange={handleVisibilityChange}
                  />
                </div>
              </div>
            </div>
            <div className="upload-popup-form-body-title">
              <TextInputComponent
                label="Title"
                state="Default"
                icon="false"
                handleInput={handleInputTitle}
                placeholder="Write a title"
                initialValue={video.title}
                restriction={true}
                toolTip={true}
                toolTipText="The video title must be less than 100 characters."
              />
            </div>
            <div className="upload-popup-form-body-description">
              <RichTextEditorComponent
                title={"Description"}
                setContent={setDescription}
                object={video}
              />
            </div>
            <div className="upload-popup-form-body-learning-objective">
              <MultipleComponent
                label="Categories"
                placeholderText="Enter your categories"
                handleTagChange={handleInputLearningObjective}
                initialInput={categories}
                categorySelector={true}
                limit={3}
              />
            </div>
            <div className="upload-popup-form-body-tag">
              <MultipleComponent
                label="Tag"
                handleTagChange={handleTagChange}
                placeholder="Enter your tag"
                initialInput={tags}
                isLowerCased={true}
              />
            </div>
            <div className="upload-bottom-component">
              {saving ? (
                <div>
                  <Spinner size="small" />
                </div>
              ) : (
                <ButtonComponent
                  icon={false}
                  text="Cancel"
                  level="secondary"
                  handleClick={closePage}
                />
              )}
              <div className="upload-bottom-component-right">
                <ButtonComponent
                  text={saving ? "Saving..." : "Save"}
                  level={readyToPublish && !saving ? "primary" : "disable"}
                  icon={false}
                  handleClick={handlePublishClick}
                />
              </div>
            </div>
          </div>
          <div className="upload-popup-form-body-right">
            <div className="upload-popup-form-body-right-title" id="tips">
              Stats
            </div>
            <div className="video-stats">
              <div className="views">Views: {parseViews(video.views || 0)}</div>
              <div className="vertical-line"></div>
              <div className="favorites">
                {" "}
                Favorites: {parseViews(video.likes_count || 0)}
              </div>
              <div className="vertical-line"></div>
              <div className="playlists">
                {" "}
                Playlists: {parseViews(video.playlists_count || 0)}
              </div>
            </div>
            <div className="upload-popup-form-hori-line"></div>
            <div className="stat-block">
              <div className="stat-block-headline">Video Link:</div>
              <div className="stat-block-description">
                <Link to={`/video/${videoId}`}>{"video/" + videoId}</Link>
              </div>
            </div>
            <div className="stat-block">
              <div className="stat-block-headline">Disable comments:</div>
              <div className="stat-block-description">Enable</div>{" "}
              {/* Currently all videos have enabled comments */}
            </div>
            <div className="stat-block">
              <div className="stat-block-headline">Visibility:</div>
              <div className="stat-block-description">
                {visibility?.charAt(0).toUpperCase() + visibility?.slice(1)}
              </div>
            </div>
            <div className="stat-block">
              <div className="stat-block-headline">Orientation:</div>
              <div className="stat-block-description">
                {videoType?.charAt(0).toUpperCase() + videoType?.slice(1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EditVideo;
