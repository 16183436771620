import crossSign from "../../assets/cross-sign.svg";
import React, { useState, useEffect } from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import "./styles.scss";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown";

const DropdownMultiple = ({
  headerText,
  options,
  setShowDropdown,
  before,
  after,
  chosenOptions,
  handleSave,
}) => {
  /*
    Dropdown with multiple options that can be selected. Currently used for Playlist dropdown.
      - headerText: header of the dropdown
      - options: options to select from
      - setShowDropdown: closing function  
      - before: elements to show before options
      - after: elements to show after options
      - chosenOptions: selected options
      - handleSave: function to save selection
  */
  
  const [newOptions, setNewOptions] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(false);

  useEffect(() => {
    setNewOptions(chosenOptions || []);
  }, [chosenOptions]);

  const handleOptionClick = (optionId) => {
    const wasOptionAdded = newOptions.find((option) => option.id === optionId);
    setNewOptions((prevOptions) => {
      if (!wasOptionAdded) {
        const newOption = options.find((option) => option.id === optionId);
        return [...prevOptions, newOption];
      } else {
        return prevOptions.filter((option) => option.id !== optionId);
      }
    });
  };

  const toggleOption = (optionId) => {
    handleOptionClick(optionId);
  };

  return (
    <AnimatedDropdown
      className={`dropdown-multiple`}
      closeDropdown={closeDropdown}
      closeFunc={() => setShowDropdown(false)}
    >
      <div className="dropdown-header">
        <span>{headerText}</span>
        <button onClick={() => setCloseDropdown(true)}>
          <img src={crossSign} />
        </button>
      </div>
      {before}
      <ul className="dropdown-list-multiple">
        {options.map((option) => (
          <li
            key={option.id}
            className="dropdown-item"
            onClick={() => toggleOption(option.id)}
          >
            <input
              type="checkbox"
              id={option.id}
              name="report"
              checked={newOptions.some(
                (newOption) => newOption.id === option.id
              )}
            />
            <label className="dropdown-label">
              {option.title.length < 28
                ? option.title
                : option.title.slice(0, 27) + "..."}
            </label>
          </li>
        ))}
      </ul>
      {after}
      <div className="dropdown-buttons">
        <ButtonComponent
          text="Cancel"
          size="small"
          level="secondary"
          icon={false}
          handleClick={() => setCloseDropdown(true)}
          coverFullWidth={true}
        />
        <ButtonComponent
          text="Save"
          size="small"
          level="primary"
          icon={false}
          handleClick={() => handleSave(newOptions)}
          coverFullWidth={true}
        />
      </div>
    </AnimatedDropdown>
  );
};

export default DropdownMultiple;
