import { combineReducers } from "redux";
import auth from "./auth";
import cardReducer from "./card";
import playerReducer from "./player";
import videoReducer from "./video";
import playlistReducer from "./playlist";
import { dataReducer } from "./data";
import globalReducer from "../reducers/global.store";

export default combineReducers({
  auth,
  card: cardReducer,
  video: videoReducer,
  playlist: playlistReducer,
  data: dataReducer,
  global: globalReducer,
  player: playerReducer,
});
