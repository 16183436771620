import { ApiService } from "./api.service.ts";
import { AxiosRequestConfig } from "axios";

class UserService {
  static instance: UserService;

  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  constructor() {
    if (UserService.instance) {
      throw new Error(
        "Error: Instantiation failed: Use UserService.getInstance() instead of new."
      );
    }
    UserService.instance = this;
  }

  async getUser(username: string): Promise<any> {
    return await ApiService.get(`/user/${username}/`);
  }

  async getUserVideos(): Promise<any> {
    return await ApiService.get("/get-user-videos/");
  }

  async getUserPlaylists(): Promise<any> {
    return await ApiService.get("/playlists/user/");
  }

  async getWatchHistory(): Promise<any> {
    return await ApiService.get("/user/watch-history/");
  }

  async getUserNotes(): Promise<any> {
    return await ApiService.get("/user/notes/");
  }

  async getUserFavorites(): Promise<any> {
    return await ApiService.get("/user/liked/");
  }

  async subscribe(userId: string): Promise<any> {
    return await ApiService.post(`/user/subscribe`, {
      userId,
    });
  }

  async unsubscribe(userId: string): Promise<any> {
    return await ApiService.post(`/user/unsubscribe`, {
      userId,
    });
  }

  async edit_profile(form_data: {}): Promise<any> {
    const config: Partial<AxiosRequestConfig> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await ApiService.patch(`/user/edit`, form_data, config);
  }

  async change_avatar(data): Promise<any> {
    const config: Partial<AxiosRequestConfig> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await ApiService.patch(`/user/edit/avatar`, data, config);
  }

  async edit_interests(data): Promise<any> {
    return await ApiService.post(`/user/edit/interests`, data);
  }

  async getCommunicationPreference(): Promise<any> {
    return await ApiService.get("/user/communication-preference");
  }

  async setCommunicationPreference(preference: string): Promise<any> {
    return await ApiService.post("/user/communication-preference", {
      communication_preference: preference,
    });
  }

  async exportSaleComparisonData(data: any): Promise<any> {
    // return await fetch(`${import.meta.env.VITE_API_BASE_URL}/report/mis/sale-comparison/export`, {
    // 	// return await fetch(`${import.meta.env.VITE_API_BASE_URL}/report/mis/sale/export`, {
    // 	method: 'POST',
    // 	headers: {
    // 		'access-token': Cookie.Get('access_token'),
    // 		'Content-Type': 'application/json'
    // 	},
    // 	body: JSON.stringify(data)
    // })
    // 	.then((res) => {
    // 		if (res.ok) {
    // 			return res.blob()
    // 		}
    // 		return Promise.reject(res)
    // 	})
    // 	.then((response) => {
    // 		return response
    // 	})
  }
}

export const userService = UserService.getInstance();
