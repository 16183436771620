import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_FAIL,
  GITHUB_AUTH_FAIL,
  LOGOUT,
} from "./types";
import { notifyError, notifySuccess } from "../actions/global.action";
import { Cookie } from "../utils/cookie.ts";
import { AUTH_TOKEN } from "../utils/enum.ts";
import authService from "../services/auth.service.ts";

export const load_user = () => async (dispatch) => {
  try {
    const res = await authService.loadUser();

    dispatch({
      type: USER_LOADED_SUCCESS,
      payload: res,
    });
  } catch (err) {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const googleAuthenticate = () => async (dispatch) => {
  try {
    await authService.get_social_code("google-oauth2");
  } catch (e) {
    console.log("Google authentication error: ", e);
    dispatch(notifyError("Google authentication failed. Please try again."));
    dispatch({
      type: GOOGLE_AUTH_FAIL,
    });
  }
};

export const githubAuthenticate = () => async (dispatch) => {
  try {
    await authService.get_social_code("github");
  } catch (e) {
    console.log("GitHub authentication error: ", e);
    dispatch(notifyError("GitHub authentication failed. Please try again."));
    dispatch({
      type: GITHUB_AUTH_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  const token =
    Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN) || localStorage.getItem("access");
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const body = JSON.stringify({
      token,
    });

    try {
      const res = await authService.checkJwtToken(body, config);

      if (res.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    const res = await authService.login({ email, password });
    Cookie.Set(AUTH_TOKEN.ACCESS_TOKEN, res.access);
    Cookie.Set(AUTH_TOKEN.REFRESH_TOKEN, res.refresh);

    localStorage.setItem("access", res.access);
    localStorage.setItem("refresh", res.refresh);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res,
    });

    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const signup =
  (first_name, last_name, email, password, re_password, callback) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      re_password,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/`,
        body,
        config,
      );

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data,
      });
      dispatch(notifySuccess("Register successfully"));
      callback(true);
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
      });
      dispatch(notifyError(err.message));
    }
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ uid, token });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/activation/`,
      body,
      config,
    );

    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
      body,
      config,
    );

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
        body,
        config,
      );

      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
