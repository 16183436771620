import React, { useCallback, useEffect, useState } from "react";
import {
  EditorProvider,
  useEditor,
  useCurrentEditor,
  EditorContent,
} from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold } from "@fortawesome/free-solid-svg-icons/faBold";
import { faItalic } from "@fortawesome/free-solid-svg-icons/faItalic";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faLinkSlash } from "@fortawesome/free-solid-svg-icons/faLinkSlash";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons/faQuoteLeft";
import { faHeading } from "@fortawesome/free-solid-svg-icons/faHeading";
import { faListUl } from "@fortawesome/free-solid-svg-icons/faListUl";
import { faListOl } from "@fortawesome/free-solid-svg-icons/faListOl";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons/faRotateLeft";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { faLaptopCode } from "@fortawesome/free-solid-svg-icons/faLaptopCode";

//Part of My Notes tab of Dashboard

const MenuBar = () => {
  const { editor } = useCurrentEditor();
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  if (!editor) {
    return null;
  }

  return (
    <div className="toolbar">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faBold} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faItalic} />
      </button>
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleStrike().run()}*/}
      {/*	disabled={*/}
      {/*		!editor.can()*/}
      {/*		       .chain()*/}
      {/*		       .focus()*/}
      {/*		       .toggleStrike()*/}
      {/*		       .run()*/}
      {/*	}*/}
      {/*	className={editor.isActive('strike') ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	strike*/}
      {/*</button>*/}
      <button
        onClick={setLink}
        className={editor.isActive("link") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faLink} />
      </button>
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().unsetLink().run()}*/}
      {/*	disabled={!editor.isActive('link')}*/}
      {/*>*/}
      {/*	<FontAwesomeIcon icon={faLinkSlash} />*/}
      {/*</button>*/}
      {/*<EditorContent editor={editor} />*/}

      {/*<button onClick={() => editor.chain().focus().unsetAllMarks().run()}>*/}
      {/*	clear marks*/}
      {/*</button>*/}
      {/*<button onClick={() => editor.chain().focus().clearNodes().run()}>*/}
      {/*	clear nodes*/}
      {/*</button>*/}
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive("blockquote") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faQuoteLeft} />
      </button>
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().setParagraph().run()}*/}
      {/*	className={editor.isActive('paragraph') ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	paragraph*/}
      {/*</button>*/}
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}*/}
      {/*	className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	h1*/}
      {/*</button>*/}
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}*/}
      {/*	className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	<FontAwesomeIcon icon={faHeading} />*/}
      {/*</button>*/}
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}*/}
      {/*	className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	h3*/}
      {/*</button>*/}
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}*/}
      {/*	className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	h4*/}
      {/*</button>*/}
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}*/}
      {/*	className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	h5*/}
      {/*</button>*/}
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}*/}
      {/*	className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	h6*/}
      {/*</button>*/}
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faListUl} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faListOl} />
      </button>
      {/*<button onClick={() => editor.chain().focus().setHorizontalRule().run()}>*/}
      {/*	horizontal rule*/}
      {/*</button>*/}
      {/*<button onClick={() => editor.chain().focus().setHardBreak().run()}>*/}
      {/*	hard break*/}
      {/*</button>*/}
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        <FontAwesomeIcon icon={faRotateLeft} />
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        <FontAwesomeIcon icon={faRotateRight} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={editor.isActive("code") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faCode} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive("codeBlock") ? "is-active" : ""}
      >
        <FontAwesomeIcon icon={faLaptopCode} />
      </button>
      {/*<button*/}
      {/*	onClick={() => editor.chain().focus().setColor('#958DF1').run()}*/}
      {/*	className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}*/}
      {/*>*/}
      {/*	purple*/}
      {/*</button>*/}
    </div>
  );
};

const RichTextEditor = ({
  content,
  setNoteData,
  shouldDisable,
  setEditor = null,
  disableMenu = false,
  placeholder,
  isIncreasedHeight = false,
}) => {
  const [editable, setEditable] = useState(false);

  const extensions = [
    Placeholder.configure({
      placeholder: placeholder || "Write a description", 
    }),
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
    Link.configure({
      openOnClick: false,
    }),
  ];

  const handleUpdate = (data) => {
    if (setNoteData) {
      const noteData = data.editor.getJSON();
      if (noteData?.content[0]?.content?.length) {
      }
      setNoteData(noteData);
    }

    setEditor && setEditor(data.editor);
  };

  useEffect(() => {
    if (content) {
    }
  }, []);

  const editor = useEditor({
    editable,
    content,
    extensions,
    onUpdate: handleUpdate,
  });

  useEffect(() => {
    if (!editor) {
      return undefined;
    }

    editor.setEditable(editable);
    setEditor && setEditor(editor);
  }, [editor, editable]);

  if (!editor) {
    return null;
  }

  const editorStyle = isIncreasedHeight ? {
    height: "min(calc(100vh - 500px), 400px)",
  } : {};

  return (
    <div className={`rich-text-editor ${isIncreasedHeight ? "is-increased-height" : ""}`}
          style={editorStyle}>
      {shouldDisable ? (
        <EditorProvider
          slotBefore={disableMenu && <MenuBar />}
          extensions={extensions}
          // content={content}
          // onUpdate={handleUpdate}
          // editorProps={{editable}}
        >
          <EditorContent editor={editor} readOnly={true} style={editorStyle} />
        </EditorProvider>
      ) : (
        <EditorProvider
          slotBefore={disableMenu && <MenuBar />}
          extensions={extensions}
          content={content}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default RichTextEditor;
