import { intlFormatDistance } from "date-fns";

export const parseViews = (views) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 0,
  }).format(views);
};

export const parseViewCount = (viewStr) => {
  return parseInt(viewStr.replace(/,/g, ''), 10); 
};

export const formatSimpleDate = (
  datetimeStr,
  options = { month: "short", day: "numeric", year: "numeric" },
) => {
  const date = new Date(datetimeStr);
  return date.toLocaleDateString("en-US", options);
};

export const getRelativeDate = (datetimeStr) => {
  const date = new Date(datetimeStr);
  return intlFormatDistance(date, new Date());
};

export const formatTimeAgo = (datetimeStr) => {
  const now = new Date();
  const uploadedDate = new Date(datetimeStr);
  const seconds = Math.floor((now - uploadedDate) / 1000);
  
  const pluralize = (value, word) => value === 1 ? `${value} ${word} ago` : `${value} ${word}s ago`;

  let interval = seconds / 31536000;  
  if (interval > 1) {
    return pluralize(Math.floor(interval), "year");
  }

  interval = seconds / 2592000;  
  if (interval > 1) {
    return pluralize(Math.floor(interval), "month");
  }

  interval = seconds / 604800;  
  if (interval > 1) {
    return pluralize(Math.floor(interval), "week");
  }

  interval = seconds / 86400;  
  if (interval > 1) {
    return pluralize(Math.floor(interval), "day");
  }

  interval = seconds / 3600;  
  if (interval > 1) {
    return pluralize(Math.floor(interval), "hour");
  }

  interval = seconds / 60;  
  if (interval > 1) {
    return pluralize(Math.floor(interval), "minute");
  }

  return pluralize(Math.floor(seconds), "second");
};

export const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds =  Math.floor(seconds % 60);
  
  // Make minutes & seconds two-digit
  const minutesStr = String(minutes).padStart(2, '0');
  const secondsStr = String(remainingSeconds).padStart(2, '0'); 
  
  return `${minutesStr}:${secondsStr}`;
}