import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { reset_password } from '../actions/auth';
import authService from "../services/auth.service.ts";
import { PASSWORD_RESET_FAIL, PASSWORD_RESET_SUCCESS } from "../actions/types";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const requestResetPassword = async (email) => {
    try {
      const response = await authService.resetPassword(email);
      setRequestSent(true);
      dispatch({ type: PASSWORD_RESET_SUCCESS });
    } catch (e) {
      dispatch({ type: PASSWORD_RESET_FAIL });
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    // reset_password(email);
    requestResetPassword(email);
  };

  if (requestSent) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container mt-5">
      <h1>Request Password Reset:</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            className="form-control"
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <button className="btn btn-primary" type="submit">
          Reset Password
        </button>
      </form>
    </div>
  );
};

// export default connect(null, { reset_password })(ResetPassword);
export default ResetPassword;
