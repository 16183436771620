import React, { useState } from "react";
import "./styles.scss";

const ToggleComponent = ({
  initialState = false,
  handleToggleSwitch,
  isAiSearch = false,
  toggleRef,
}) => {
  /*
    Alliged with latest Figma design component (May 15)
    handlgeToggleSwitch: callback prop function that handles toggle switch change
    */

  const [state, setState] = useState(initialState);

  const handeSwitch = () => {
    setState(!state);
    handleToggleSwitch(!initialState);
  };

  return (
    <div
      className="toggle-component"
      onClick={handeSwitch}
      ref={toggleRef ? toggleRef : null}
    >
      <div className={`toggle-component-bar${state ? " on" : ""}`}></div>
      <div className={`toggle-component-circle${state ? " on" : ""}`}>
        {isAiSearch ? "AI" : ""}
      </div>
    </div>
  );
};

export default ToggleComponent;
