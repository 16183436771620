import ThumbnailComponent from "../ThumbnailComponent/ThumbnailComponent";
import { useNavigate } from "react-router-dom";
import React, { useRef, useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { notifyError } from "../../actions/global.action";
import ImageCropper from "../ImageCropComponent/ImageCrop";
const ThumbnailChangeComponent = ({
  video,
  img,
  setImg,
  setThumbnail,
  redirect = false,
  setThumbnailSec = null,
}) => {
  /*
    Component that handles thumbnail change. Currently used when uploading video (UploadPopUpForm.js) and editing it (EditVideo.js).
      - video: video object 
      - img: variable for displaying the thumbnail
      - setImg: function to change thumbnail display
      - setThumbnail: function to store the new thumbnail file
      - redirect: boolean that determines whether click on the thumbnail should open video page
  */
  const thumbnailUploadRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imgSrc, setImgSrc] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const ASPECT_RATIO = video.video_type === "landscape" ? 16 / 9 : 9 / 16;
  const MIN_DIMENSION = 150;
  const [fileType, setFileType] = useState();

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (file.size > 5 * 1024 * 1024) {
      dispatch(notifyError("File size should be less than 5 MB"));
      return;
    }
    setFileType(file.type);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          dispatch(notifyError("Image must be at least 150 x 150 pixels."));
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
    setShowCrop(true);
  };

  const handleClose = () => {
    setShowCrop(false);
  };

  const handleClickThumbnail = () => {
    thumbnailUploadRef.current.click();
  };

  const removeThumbnail = () => {
    if (setThumbnailSec) {
      setImg(video.original_thumbnail_url);
      setThumbnailSec(1);
    } else {
      setImg(null);
    }
    setThumbnail(null);
  };

  const openVideo = () => {
    if (redirect) {
      navigate(`/video/${video.id}`);
    }
  };
  return (
    <div className="thumbnail-edit-container">
      <input
        ref={thumbnailUploadRef}
        type="file"
        style={{ display: "none" }}
        onChange={onSelectFile}
        accept=".jpg,.jpeg,.png"
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <div className="video-thumbnail" onClick={() => openVideo()}>
        <ThumbnailComponent video={video} thumbnail={img} />
      </div>
      <div className="instruction">
        (Supported file types: JPG, JPEG, and PNG. Max file size: 5 MB)
      </div>
      <div className="thumbnail-edit">
        <div onClick={() => handleClickThumbnail()} className="thumbnail-btn">
          Change
        </div>
        {
          <div onClick={() => removeThumbnail()} className="thumbnail-btn">
            Remove
          </div>
        }
      </div>
      {showCrop && (
        <ImageCropper
          imgSrc={imgSrc}
          handleClose={handleClose}
          setImg={setImg}
          ASPECT_RATIO={ASPECT_RATIO}
          setThumbnail={setThumbnail}
          fileType={fileType}
        />
      )}
    </div>
  );
};
export default ThumbnailChangeComponent;
