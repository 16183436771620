import React from "react";
import LoginForm from "../../components/AuthForm/LoginForm.js";
import SignupForm from "../../components/AuthForm/SignupForm.js";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import { useSelector } from "react-redux";
import authService from "../../services/auth.service.ts";
import Spinner from "../../components/Spinner/Spinner.js";

const AuthPage = () => {
  /*
        Page that contains the login and signup forms
    */

  const navigate = useNavigate();
  const location = useLocation();
  const [showLogin, setShowLogin] = useState(location.pathname === "/login");
  const [showSignup, setShowSignup] = useState(location.pathname === "/signup");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const showLoginSelector = useSelector((state) => state.global.showLogin);
  const showSignupSelector = useSelector((state) => state.global.showSignup);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!showLogin && !showSignup) {
      navigate("/");
    } else if (showLoginSelector) {
      navigate("/login");
    } else if (showSignupSelector) {
      navigate("/signup");
    }
  }, [showLogin, showSignup, showLoginSelector, showSignupSelector]);

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate("/");
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="auth-page">
      {showLogin ? (
        <LoginForm setShowLogin={setShowLogin} setShowSignup={setShowSignup} />
      ) : showSignup ? (
        <SignupForm setShowLogin={setShowLogin} setShowSignup={setShowSignup} />
      ) : null}
    </div>
  );
};

export default AuthPage;
