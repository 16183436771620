import React from "react";
import './styles.scss';


const HamburgerItem = ({ icon, textToDisplay, selected = false, handleClick }) => {
    /*
        An item in the hamburger menu accessible via the three lines icon on the left of the Navigation Bar.
    */

    return(
        <div className={`hamburger-chapter-item ${selected? "selected" : ""}`} onClick={() => {handleClick(textToDisplay)}}> 
            <div className="hamburger-chapter-item-icon">
                <img src={icon} alt="icon" />
            </div>
            <div className="hamburger-chapter-item-text">
                {textToDisplay}
            </div>
        </div>

    );
}

export default HamburgerItem;