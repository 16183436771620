import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPlaylist,
  fetchPlaylists,
  updatePlaylistStructure,
  updatePlaylistVisibility,
} from "../../actions/playlist";
import useOutsideClick from "../../hooks/useOutsideClick";
import "./styles.scss";
import { SimpleTreeItemWrapper } from "./SimpleTreeItemWrapper.tsx";
import AlertModal from "../../modals/AlertModal/AlertModal";
import { setWatchingPlaylist } from "../../actions/playlist";
import { notifyError, notifySuccess } from "../../actions/global.action.js";
import { checkIsDuplicatePlaylist } from "../../hooks/usePlaylist.ts";
import PrivatePlaylist from "../../assets/private.svg";
import PublicPlaylist from "../../assets/public.svg";
import UnlistedPlaylist from "../../assets/unlisted.svg";
import SettingsIcon from "../../assets/edit-icon.svg";
import DropdownMenuPlaylistComponent from "../DropdownMenuPlaylistComponent/DropdownMenuPlaylistComponent.js";
import { showModal, hideModal } from "../../actions/global.action.js";
import plus from "../../assets/plus.svg";
import { playlistService } from "../../services/playlist.service.ts";
import TooltipComponent from "../TooltipComponent/TooltipComponent.js";
import assessmentIcon from "../../assets/multiple-choice.svg";
import flashcardIcon from "../../assets/flashcard.svg";
import knowledgeIcon from "../../assets/informational.svg";
import videoIcon from "../../assets/video-recorder.svg";

const cardTypeImage = {
  assessment: assessmentIcon,
  knowledge: knowledgeIcon,
  flashcard: flashcardIcon,
};

const DraggablePlaylistItem = React.forwardRef((props, ref) => {
  /*
  Accessible from  Dashboard > My Playlists, in the left column.
  Function that handles actions associated with a playlist, including:
    - Settings (three dots)
      - Watch
      - Edit
      - Share
      - Duplicate
      - Delete
    - Privacy change
    - Add video  
*/
  const dispatch = useDispatch();
  const items = useSelector((state) => state.playlist.playlists);
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist
  );
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist
  );
  const [selectedVisibility, setSelectedVisibility] = useState(false);
  const [videoSettings, setVideoSettings] = useState(false);
  const [cardSettings, setCardSettings] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningDeleteVideo, setShowWarningDeleteVideo] = useState(false);
  const [showWarningDeleteCard, setShowWarningDeleteCard] = useState(false);
  const [showWarningEdit, setShowWarningEdit] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  const handlePopoverClick = (event) => {
    setIsMenuOpen((prev) => !prev);
  };

  const handlePopoverClickPrivacy = () => {
    setSelectedVisibility(true);
  };

  const handleVideoSettingsClick = () => {
    setVideoSettings(true);
  };

  const handleCardSettingsClick = () => {
    setCardSettings(true);
  };

  useOutsideClick(menuRef, () => {dispatch(hideModal());});

  const handleDeleteClick = () => {
    setShowWarning(true);
  };

  const handleDeleteClickEdit = () => {
    setShowWarningEdit(true);
  };

  const handleConfirmDelete = () => {
    setShowWarning(false);
    
    const checkChildrenForPlaylist = (item, objectId) => {
      if (item.object_id === objectId) {
        return true;
      }
      if (item.children && item.children.length > 0) {
        return item.children.some(child => checkChildrenForPlaylist(child, objectId));
      }
      return false;
    };
    
    const isPlaylistInView = checkChildrenForPlaylist(props.item, currentPlaylist?.object_id);

    handleDeletePlaylist(props.item);
    const url = new URL(window.location);
    if (url.searchParams.has("playlistId") && isPlaylistInView)
    {
          url.searchParams.delete("playlistId");
          dispatch(setWatchingPlaylist(false));
          window.history.replaceState({}, "", url.toString());}
  };

  const handleConfirmDeleteVideo = () => {
    setShowWarningDeleteVideo(false);
    handleRemove(props.item, "video");
  };

  const handleConfirmDeleteCard = () => {
    setShowWarningDeleteCard(false);
    handleRemove(props.item, "card");
  };

  const handleCancelDelete = () => {
    setShowWarning(false);
  };

  const handleCancelDeleteVideo = () => {
    setShowWarningDeleteVideo(false);
  };
  
  const handleCancelDeleteCard = () => {
    setShowWarningDeleteCard(false);
  };

  const handleCancelDeleteEdit = () => {
    setShowWarningEdit(false);
  };

  const handleConfirmDeleteEdit = () => {
    setShowWarningEdit(false);
    handleHighlight(props.item, "playlist");
    dispatch(setCurrentPlaylist(props.item));
    dispatch(setWatchingPlaylist("edit"));
  };

  const handleAddClick = () => {
    dispatch(setCurrentPlaylist(props.item));
    dispatch(updatePlaylistStructure(items, props.item));
    dispatch(showModal("add-video-to-playlist"));
  };

  const handleClick = () => {
    dispatch(setCurrentPlaylist(props.item));
    dispatch(updatePlaylistStructure(items, props.item));
    dispatch(showModal("add-video-to-playlist"));
  };

  const handleHighlight = (item, type) => {
    dispatch(setCurrentPlaylist(props.item));
    dispatch(setCurrentPlaylist(item));
    dispatch(setWatchingPlaylist("edit"));
  };

  const handleWatch = (item, type) => {
    if (type === "playlist") {
      dispatch(setCurrentPlaylist(props.item));
      dispatch(setCurrentPlaylist(item));
      window.open(`/playlist/${props.item.object_id}`, '_blank');
    } else if (type === "video") {
      dispatch(setCurrentPlaylist(item));
      window.open(`/video/${props.item.object_id}`, '_blank');
    } else {
      window.open(`/card/${props.item.object_id}`, '_blank');
    }
  };

  const handleEdit = (item) => {
    if (showPlaylistWatch === "create") {
      handleDeleteClickEdit(props.item, "playlist");
    } else {
      handleHighlight(props.item, "playlist");
      dispatch(setCurrentPlaylist(props.item));
      dispatch(setCurrentPlaylist(item));
      dispatch(setWatchingPlaylist("edit"));
    }
  };

  const handleShare = (item, item_type) => {
    const textToCopy = `${window.location.origin}/${item_type}/${item.object_id}/`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        dispatch(notifySuccess("URL copied to clipboard!"));
      })
      .catch((err) => {
        dispatch(notifyError(`Error copying to clipboard. ${err}`));
      });
  };

  const handleVisibilityChange = (newVisibility) => {
    setSelectedVisibility(false);

    playlistService
      .changePlaylistVisibility(props.item.object_id, newVisibility)
      .then(() => {
        // Handle response
        dispatch(notifySuccess("Visibility changed successfully!"));
        dispatch(fetchPlaylists());
        if (currentPlaylist?.object_id === props.item.object_id) {
          dispatch(updatePlaylistVisibility(newVisibility));
        }
      })
      .catch((error) => {
        // Handle error
        dispatch(
          notifyError(
            `Error changing visibility! ${
              error.response ? error.response.data : error.message
            }`
          )
        );
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const handleVideoSettings = (option) => {
    setVideoSettings(false);
    if (option == "watch") {
      handleWatch(props.item, "video");
    } else if (option == "share") {
      handleShare(props.item, "video");
    } else if (option == "remove") {
      setShowWarningDeleteVideo(true);
    }
  };

  const handleCardSettings = (option) => {
    setCardSettings(false);
    if (option == "preview") {
      handleWatch(props.item, "card");
    } else if (option == "share") {
      handleShare(props.item, "card");
    } else if (option == "remove") {
      setShowWarningDeleteCard(true);
    }
  };

  const handleMenuClick = (option) => {
    setIsMenuOpen(false);
    if (option == "watch") {
      handleWatch(props.item, "playlist");
    } else if (option == "edit") {
      handleEdit(props.item);
    } else if (option == "share") {
      handleShare(props.item, "playlist");
    } else if (option == "duplicate") {
      handleDuplicate(props.item);
    } else if (option == "delete") {
      handleDeleteClick(props.item);
    }
  };
  const handleDeletePlaylist = (item) => {
    playlistService
      .deletePlaylist(item.object_id)
      .then((response) => {
        dispatch(notifySuccess("Playlist deleted successfully"));
        dispatch(fetchPlaylists()); // Fetch the updated list of playlists
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const handleDuplicate = (item) => {
    let whichId = "";
    if (item?.parent) {
      whichId = item.parent?.object_id;
      if (item.parent?.nestLvl === 0) {
      }
      if (item.parent.children.length === 0) {
      } else {
        const isDuplicated = checkIsDuplicatePlaylist(
          `${item.title.trim()} (Copy)`,
          item.parent.children
        );
        if (isDuplicated) {
          dispatch(notifyError("You have already duplicated this playlist"));
          return;
        }
      }
      return;
    } else {
      whichId = item.object_id;
      if (items.length === 0) {
      } else {
        const isDuplicated = checkIsDuplicatePlaylist(
          `${item.title.trim()} (Copy)`,
          items
        );
        if (isDuplicated) {
          dispatch(notifyError("You have already duplicated this playlist"));
          return;
        }
      }
    }
    playlistService
      .duplicatePlaylist(
        item.object_id,
        item.parent ? item.parent.object_id : item.object_id
      )
      .then((response) => {
        // Handle success
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        // Handle error
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const handleRemove = (item, type) => {
    playlistService
      .deleteFromPlaylist(item.playlist_item_id)
      .then((response) => {
        dispatch(notifySuccess(`${type === "video" ? "Video" : "Card"} removed successfully`));
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        console.error("Error removing playlist item:", error.message);
      });
  };

  const privacyIcon = () => {
    switch (props?.item?.visibility) {
      case "public":
        return (
          <button className="btn privacy" onClick={handlePopoverClickPrivacy}>
            <img src={PublicPlaylist} className="privacy" />
          </button>
        );
      case "private":
        return (
          <button className="btn privacy" onClick={handlePopoverClickPrivacy}>
            <img src={PrivatePlaylist} className="privacy" />
          </button>
        );
      case "unlisted":
        return (
          <button className="btn privacy" onClick={handlePopoverClickPrivacy}>
            <img src={UnlistedPlaylist} className="privacy" />
          </button>
        );
      default:
        return <></>;
    }
  };

   useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
}, [textRef])

  let newWidth = `${200 - props.depth * 20}px`;
  newWidth = props.depth === 0 ? "175px" : newWidth;
  newWidth = props.childCount > 0 ? `${parseInt(newWidth) - 20}px` : newWidth;
  newWidth = props.depth >= 6 ? "20px" : newWidth;

  return (
    /* you could also use FolderTreeItemWrapper if you want to show vertical lines.  */
    props.item.video_url ? (
      <SimpleTreeItemWrapper
        {...props}
        ref={ref}
        onCollapse={null}
        isVideo={true}
        isSelected={
          currentPlaylist?.object_id === props.item.object_id &&
          showPlaylistWatch === "watch-video"
        }
      >
        <>
          {" "}
          {showWarningDeleteVideo && (
            <AlertModal
              type="danger"
              onContinue={handleConfirmDeleteVideo}
              onCancel={handleCancelDeleteVideo}
              title="Remove Video"
              message="Are you sure you want to remove video from this playlist?"
            />
          )}
          <>
            {
              <>
                <>{(props.item.canHaveChildren = false)}</>
                <div
                  className="inner-draggable-container"
                  onClick={handleVideoSettingsClick}
                >
                  {!videoSettings &&  isOverflowing && <TooltipComponent tipText={props.item.title} showIcon={false} placement="playlist video" />}
                  <div className="icon-container">
                    <div className="card-icon">
                      <img src={videoIcon} />
                    </div>
                  </div>
                  <div className="draggable-span-container">
                    <div className="draggable-span" ref={textRef}>{props.item.title}</div>
                  </div>
                  <div className="kebab-menu">
                    <button
                      className="btn menu-toggle"
                      onClick={handleVideoSettingsClick}
                    ></button>
                    {videoSettings ? (
                      <div style={{ position: "relative" }}>
                        <DropdownMenuPlaylistComponent
                          isPlaylistTree={true}
                          isRightAlignedProp={true}
                          type="video"
                          chosenOption={"none"}
                          handleChange={handleVideoSettings}
                          handleClose={() => setVideoSettings(false)}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            }
          </>
        </>
      </SimpleTreeItemWrapper>
    ) : props.item.card_type ? (
      <SimpleTreeItemWrapper
        {...props}
        ref={ref}
        onCollapse={null}
        isVideo={true}
        isSelected={
          currentPlaylist?.object_id === props.item.object_id &&
          showPlaylistWatch === "watch-video"
        }
      >
        <>
          {" "}
          {showWarningDeleteCard && (
            <AlertModal
              type="danger"
              onContinue={handleConfirmDeleteCard}
              onCancel={handleCancelDeleteCard}
              title="Remove Card"
              message="Are you sure you want to remove card from this playlist?"
            />
          )}
          <>
            {
              <>
                <>{(props.item.canHaveChildren = false)}</>
                <div
                  className="inner-draggable-container"
                  onClick={handleCardSettingsClick}
                >
                  {!cardSettings &&  isOverflowing && <TooltipComponent tipText={props.item.title} showIcon={false} placement="playlist card" />}
                  <div className="icon-container">
                    <div className="card-icon">
                      <img src={cardTypeImage[props.item.card_type]} />
                    </div>
                  </div>
                  <div className="draggable-span-container">
                    <div className="draggable-span" ref={textRef}>{props.item.title}</div>
                  </div>
                  <div className="kebab-menu">
                    <button
                      className="btn menu-toggle"
                      onClick={handleCardSettingsClick}
                    ></button>
                    {cardSettings && (
                      <div style={{ position: "relative" }}>
                        <DropdownMenuPlaylistComponent
                          isPlaylistTree={true}
                          isRightAlignedProp={true}
                          type="card"
                          chosenOption={"none"}
                          handleChange={handleCardSettings}
                          handleClose={() => setCardSettings(false)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </>
        </>
      </SimpleTreeItemWrapper>
    ) : (
      <SimpleTreeItemWrapper
        {...props}
        ref={ref}
        disableCollapseOnItemClick={true}
        isSelected={
          currentPlaylist?.playlist_item_id === props.item.playlist_item_id &&
          showPlaylistWatch !== "create"
        }
        isVideo={false}
        isCreating={props.item.id === "new-playlist"}
        currentPlaylistName={props.item?.title}
        className={props.item.id === currentPlaylist?.id ? "chosen" : ""}
      >
        <>
          {showWarning && (
            <AlertModal
              type="danger"
              onContinue={handleConfirmDelete}
              onCancel={handleCancelDelete}
              title="Delete Playlist"
              message="Are you sure you want to delete this playlist? This action is irreversible."
            />
          )}
          {showWarningEdit && (
            <AlertModal
              type="warning"
              onContinue={handleConfirmDeleteEdit}
              onCancel={handleCancelDeleteEdit}
              title="Cancel Creating Playlist"
              message="Are you sure you want to stop creating a playlist? This action is irreversible."
            />
          )}

            <div className="inner-draggable-container">
              {!isMenuOpen && !selectedVisibility && isOverflowing && <TooltipComponent tipText={props.item.title} showIcon={false} placement="playlist" />}
            <div
              className="draggable-span-container"
              onClick={() => handleEdit(props.item)}
              >
              <div className="draggable-span" style={{ maxWidth: newWidth }} ref={textRef}>
                {props.item.title}
              </div>
              </div>
            <div
              className="button-wrapper"
            >
              {(props.item.isRoot || props.item?.isCreating) && (
                <div className="privacy">
                  {privacyIcon()}
                  {selectedVisibility ? (
                    <div
                      style={{ position: "absolute" }}
                      className="button-wrapper-privacy"
                    >
                      <DropdownMenuPlaylistComponent
                        isPlaylistTree={true}
                        isRightAlignedProp={true}
                        type="privacy"
                        chosenOption={props?.item?.visibility}
                        handleChange={handleVisibilityChange}
                        handleClose={() => setSelectedVisibility(false)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <div className="btn btn-add" onClick={handleAddClick}>
                <img src={plus} />
              </div>
              <div className="kebab-menu">
                <button
                  className="btn menu-toggle"
                  onClick={handlePopoverClick}
                >
                  <img src={SettingsIcon} />
                </button>
                {isMenuOpen ? (
                  <div style={{ position: "relative" }}>
                    <DropdownMenuPlaylistComponent
                      isPlaylistTree={true}
                      isRightAlignedProp={true}
                      type="menu"
                      chosenOption={"none"}
                      handleChange={handleMenuClick}
                      handleClose={() => setIsMenuOpen(false)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      </SimpleTreeItemWrapper>
    )
  );
});

export default DraggablePlaylistItem;
