import { SET_CURRENT_CARD, SET_CARDS, UPDATE_CARD } from "../actions/types";

const initialState = {
  currentCard: null,
  cards: [],
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CARD:
      return {
        ...state,
        currentCard: action.payload,
      };
    case SET_CARDS:
      return {
        ...state,
        cards: action.payload,
      };
    case UPDATE_CARD:
      return {
        ...state,
        cards: state.cards.map((card) =>
          card.id === action.payload.id ? action.payload : card,
        ),
      };
    default:
      return state;
  }
};

export default cardReducer;
