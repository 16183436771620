import { SET_CURRENT_CARD, SET_CARDS, UPDATE_CARD } from "./types";
import { cardService } from "../services/card.service";

export const setCurrentCard = (card) => ({
  type: SET_CURRENT_CARD,
  payload: card,
});

export const setCards = (cards) => ({
  type: SET_CARDS,
  payload: cards,
});

export const fetchCards = () => async (dispatch) => {
  try {
    const cards = await cardService.getUserCards();
    dispatch(setCards(cards));
  } catch (error) {
    console.error("Error fetching cards", error);
  }
};

export const updateCard = (updatedCard) => ({
  type: UPDATE_CARD,
  payload: updatedCard,
});
