import React, { useState } from "react";
import "./styles.scss";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const CreatePlaylistModal = ({ handleCancel, handleCreate }) => {
  /*
    A pop up to create a new playlist when 
    a user wants to add their video to a playlist.

    handleCancel:
      - function that handles canceling playlist creation. 
    handleCreate:
      - function that handles playlist creation. 
  */
  const [title, setTitle] = useState("");

  return (
    <div className="create-playlist-modal">
      <div className="playlist-modal-content">
        <div className="playlist-name-input">
          <TextInputComponent
            handleInput={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Playlist name"
            initialValue={title}
            restriction={true}
            toolTip={true}
            toolTipText="The playlist title must be less than 100 characters."
            label={"New Playlist"}
          />
        </div>
      </div>
      <div className="playlist-modal-footer">
        <ButtonComponent
          text="Cancel"
          icon={false}
          level="secondary"
          handleClick={handleCancel}
        />
        <ButtonComponent
          text="Create"
          icon={false}
          level={title ? "primary" : "disable"}
          handleClick={() => handleCreate(title)}
        />
      </div>
    </div>
  );
};

export default CreatePlaylistModal;
