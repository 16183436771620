import React, { useState } from "react";
import "./styles.scss";
import { fetchPlaylists } from "../../actions/playlist";
import { useDispatch, useSelector } from "react-redux";
import { checkIsDuplicatePlaylist } from "../../hooks/usePlaylist.ts";
import { notifySuccess } from "../../actions/global.action";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { playlistService } from "../../services/playlist.service.ts";
import ItemCard from "../ItemCard/ItemCard.js";

const PlaylistItem = ({ item, playlist, setClosePopup = () => {} }) => {
  /*
    Renders each available playlist item in the AddVideoToPlaylist pop out window.
    Displays duplicate buttons for each playlist.
    If a playlist is already duplicated in a selected playlist, the 'duplicate' button is disabled.
  */

  const dispatch = useDispatch();
  const items = useSelector((state) => state.playlist.playlists);
  const [isAlrDuplicated, setIsAlrDuplicated] = useState(
    checkIsDuplicatePlaylist(`${item.title} (Copy)`, items),
  );

  const duplicatePlaylist = () => {
    let whichId = "";
    whichId = playlist?.object_id || item.id;
    playlistService
      .duplicatePlaylist(item.object_id, whichId)
      .then(() => {
        dispatch(notifySuccess("Playlist duplicated successfully"));
        setIsAlrDuplicated(true);
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message,
        );
      });
  };

  return (
    <div className="playlist-item-component">
      <div className="playlist-part">
        <ItemCard
          object={item}
          type="playlist"
          handleClose={() => {
            setClosePopup(true);
          }}
        />
      </div>
      <div className="button-part">
        <div className="button-container">
          <ButtonComponent
            text={isAlrDuplicated ? "Duplicated" : "Duplicate"}
            handleClick={() => {
              duplicatePlaylist();
            }}
            icon={false}
            level={isAlrDuplicated ? "disable" : "primary"}
          />
        </div>
      </div>
    </div>
  );
};

export default PlaylistItem;
