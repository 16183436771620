import {
  FETCH_PLAYLISTS_REQUEST,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILURE,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILURE,
  SET_CURRENT_PLAYLIST,
  SET_PLAYLISTS,
  SET_WATCHING_PLAYLIST,
  UPDATE_PLAYLIST_NAME,
  UPDATE_PLAYLIST_VISIBILITY,
} from "../actions/types";

const initialState = {
  loading: false,
  playlists: [],
  watchingPlaylist: false,
  currentPlaylist: null,
  error: "",
  isCreatingPlaylist: false,
  currentPlaylistName: "",
  currentPlaylistVisibility: "",
};

const playlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAYLISTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PLAYLISTS_SUCCESS:
      const playlistData = action.payload;
      const newPlaylist = playlistData.map((item) => {
        return {
          ...item,
          isVideo: !!item?.video_url,
          parentId: null,
          isRoot: true,
          nestLvl: 0,
          children: item?.children?.map((lvl1Item) => {
            return {
              ...lvl1Item,
              isVideo: !!lvl1Item?.video_url,
              nestLvl: 1,
              parentId: item.object_id,
              children: lvl1Item?.children?.map((lvl2Item) => {
                return {
                  ...lvl2Item,
                  isVideo: !!lvl2Item?.video_url,
                  nestLvl: 2,
                  parentId: lvl1Item.object_id,
                  children: lvl2Item?.children?.map((lvl3Item) => {
                    return {
                      ...lvl3Item,
                      isVideo: !!lvl3Item?.video_url,
                      nestLvl: 3,
                      parentId: lvl2Item.object_id,
                      children: lvl3Item?.children?.map((mustBeVideoItem) => {
                        return {
                          ...mustBeVideoItem,
                          isVideo: !!mustBeVideoItem?.video_url,
                          nestLvl: 4,
                          parentId: lvl3Item.object_id,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
      // console.log('store: ', newPlaylist)
      return {
        ...state,
        loading: false,
        playlists: newPlaylist,
        error: "",
      };
    case FETCH_PLAYLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        playlists: [],
        error: action.payload,
      };
    case CREATE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: [...state.playlists, action.payload].map((item) => ({
          ...item,
          isRoot: true,
        })),
        error: null,
      };
    case CREATE_PLAYLIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CURRENT_PLAYLIST:
      return {
        ...state,
        currentPlaylist: action.payload,
      };
    case SET_PLAYLISTS:
      return {
        ...state,
        playlists: action.payload.map((item) => ({ ...item, isRoot: true })),
      };
    case SET_WATCHING_PLAYLIST:
      return {
        ...state,
        watchingPlaylist: action.payload,
      };
    case UPDATE_PLAYLIST_NAME:
      return {
        ...state,
        currentPlaylistName: action.payload,
      };
    case UPDATE_PLAYLIST_VISIBILITY:
      return {
        ...state,
        currentPlaylist: state.currentPlaylist && {
          ...state.currentPlaylist,
          visibility: action.payload,
        },
        currentPlaylistVisibility: action.payload,
      };
    default:
      return state;
  }
};

export default playlistReducer;
