import {
  CLEAR_TOAST_MESSAGE,
  NOTIFY_SUCCESS,
  NOTIFY_WARNING,
  NOTIFY_ERROR,
  NOTIFY_INFO,
  HIDE_LOGIN,
  SHOW_SIGNUP,
  HIDE_SIGNUP,
  SHOW_LOGIN,
  SHOW_MODAL,
  HIDE_MODAL,
} from "../actions/types";

const initialState = {
  toastMessage: "",
  type: "",
  showLogin: false,
  showSignUp: false,
  modalType: "",
  videoPreview: "",
  showModal: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFY_SUCCESS:
      return {
        ...state,
        toastMessage: action.payload,
        type: "success",
      };
    case NOTIFY_WARNING:
      return {
        ...state,
        toastMessage: action.payload,
        type: "warning",
      };
    case NOTIFY_ERROR:
      return {
        ...state,
        toastMessage: action.payload,
        type: "error",
      };
    case NOTIFY_INFO:
      return {
        ...state,
        toastMessage: action.payload,
        type: "info",
      };
    case CLEAR_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: "",
        type: "",
      };
    case SHOW_LOGIN:
      return {
        ...state,
        showLogin: true,
      };
    case SHOW_SIGNUP:
      return {
        ...state,
        showSignUp: true,
      };
    case HIDE_LOGIN:
      return {
        ...state,
        showLogin: false,
      };
    case HIDE_SIGNUP:
      return {
        ...state,
        showSignUp: false,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modalType: action.payload.type,
        videoPreview: action.payload?.videoPreview || "",
        showModal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalType: "",
        showModal: false,
        videoPreview: "",
      };
    default:
      return state;
  }
};

export default globalReducer;
