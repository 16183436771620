//Percentage of video that should be watched to be count as a view (send a request)
export const VIEW_VIDEO_PERCENTAGE = 0.1;

//Months list (previously used for sign up form's birthday field)
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//Boolean to determine whether bottom footer (Footer.js) that suggest sign in/up is displayed
export const showFooter = false;

//Number of videos displayed at once on the feed
export const maxVideos = 20;

//Number of videos fetched from backend (for feed batch)
export const videoBatch = 5;

//Cards types displayed on the frontend and stored in the backend
export const cardTypeDict = {
  "Multiple Choice": "assessment",
  Informational: "knowledge",
  Flashcard: "flashcard",
  assessment: "Multiple Choice",
  knowledge: "Informational",
  flashcard: "Flashcard",
};
