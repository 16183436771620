import React, { useEffect, useState } from "react";
import MultipleComponent from "../MultipleComponent/MultipleComponent";
import AuthorAvatar from "../AuthorAvatar/AuthorAvatar";
import "./styles.scss";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent";
import authService from "../../services/auth.service.ts";
import { useDispatch } from "react-redux";
import { notifyError } from "../../actions/global.action.js";

const PublicProfileTab = ({
  setReadyToSave,
  setAvatar,
  setInterests,
  setIntroduction,
}) => {
  /*
        One of the settings components.
        Displays user's public profile information.
        Fully alligned with Figma design (June 2).
    */
  const [userInfo, setUserInfo] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [userInterests, setUserInterests] = useState([]);
  const [img, setImg] = useState(null);
  const [lastName, setLastName] = useState("");
  const [channelName, setChannelName] = useState("");
  const [username, setUsername] = useState(null);
  const dispatch = useDispatch();

  setReadyToSave(true);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0]; // Assuming single file selection
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        dispatch(notifyError("File size should be less than 5 MB"));
        return;
      }
      var reader = new FileReader();
      reader.onloadend = function () {
        setImg(reader.result);
      };
      reader.readAsDataURL(file);
      setAvatar(file);
      setReadyToSave(true);
    }
  };

  const handleAvatarRemove = () => {
    setImg("");
    setAvatar("remove");
    setReadyToSave(true);
  };

  useEffect(() => {
    const fetchSelf = async () => {
      const response = await authService.loadUser();
      setUserInfo(response);
      setFirstName(response.first_name);
      setLastName(response.last_name);
      setUserInterests(response.interests);
      setImg(response.avatar || "");
      setUsername(response.username);
      setChannelName(response.channel_name || "");
    };
    fetchSelf();
  }, []);

  return (
    userInfo && (
      <div className="public-profile-tab">
        <div className="public-profile-headline">Public Profile</div>

        <div className="avatar-block">
          <div className="avatar">
            <AuthorAvatar src={img} handleClick={() => {}} author={null} />
          </div>
          <div className="right-side">
            <div className="channel-name">
              {channelName.length > 0
                ? channelName
                : firstName + " " + lastName}
              {username && <div className="username">@{username}</div>}
            </div>
            <div className="links">
              <label className="upload">
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleAvatarChange}
                  accept=".jpg,.jpeg,.png"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
                {img ? "Change" : "Upload"}
              </label>
              {img == "" || (
                <div className="upload" onClick={handleAvatarRemove}>
                  Remove
                </div>
              )}
            </div>
            <div className="instruction">
              (Supported file types: JPG, JPEG, and PNG. Max file size: 5 MB)
            </div>
          </div>
        </div>

        <RichTextEditorComponent
          title={"Introduce Yourself"}
          object={userInfo}
          setContent={setIntroduction}
          placeholder="Write an introduction to yourself..."
        />

        <div className="interest">
          <MultipleComponent
            label="Interest"
            handleTagChange={(interestsInput) => {
              setInterests(interestsInput);
            }}
            placeholderText="Enter your interests"
            icon={true}
            initialInput={userInterests}
            isLowerCased={true}
          />
        </div>
      </div>
    )
  );
};

export default PublicProfileTab;
