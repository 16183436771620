import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../actions/types";
import authService from "../../services/auth.service.ts";
import MyAccount from "../../assets/my-account.svg";
import SettingsBlack from "../../assets/settings-black.svg";
import Keyboard from "../../assets/keyboard.svg";
import Help from "../../assets/help.svg";
import LogoutBlack from "../../assets/logout-black.svg";
import "./styles.scss";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown.js";

const DropdownMyAccount = ({ handleClose, setShowKeyboardShortcuts }) => {
  /*
    Creates a dropdown menu for the landing page 
    Alligned with latest Figma design (May 24)
    Opmization todo: the clickOutside event on an icon leads to the dropdown staying open
    it's currently solved by setting a timeout, but it's not the best solution
    */

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user)?.username;
  const [closeDropdown, setCloseDropdown] = useState(false);

  const list = [
    { label: "Profile", icon: MyAccount, navigation: `/channel/${user}` },
    { label: "My Account", icon: SettingsBlack, navigation: "/settings" },
    {
      label: "Keyboard Shortcuts",
      icon: Keyboard,
    },
    { label: "Help", icon: Help, navigation: "/help" },
  ];

  const toFirstLetterUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const logout_user = async () => {
    const res = await authService.logout();
    res && dispatch({ type: LOGOUT });
  };

  return (
    <AnimatedDropdown
      className="dropdown-my-account"
      closeDropdown={closeDropdown}
      closeFunc={handleClose}
    >
      <div>
        <ul className="dropdown-list">
          {list.map((option) => (
            <li
              key={option.label}
              onClick={(e) => {
                e.stopPropagation();
                navigate(option.navigation);
                if (option.label === "Keyboard Shortcuts") {
                  setShowKeyboardShortcuts(true);
                } else {
                  navigate(option.navigation);
                }
                setCloseDropdown(true);
              }}
              className="dropdown-list-item"
            >
              <div className="dropdown-list-item-left">
                <div className="dropdown-list-option-icon">
                  <img src={option.icon} />
                </div>
                <div className="dropdown-list-option-name">
                  {toFirstLetterUpperCase(option.label)}
                </div>
              </div>
            </li>
          ))}
          <div className="divider"></div>
          <li
            key={"logout"}
            onClick={(e) => {
              e.stopPropagation();
              logout_user();
              navigate("/");
              setCloseDropdown(true);
            }}
            className="dropdown-list-item"
          >
            <div className="dropdown-list-item-left">
              <div className="dropdown-list-option-icon">
                <img src={LogoutBlack} />
              </div>
              <div className="dropdown-list-option-name">
                {toFirstLetterUpperCase("Log out")}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </AnimatedDropdown>
  );
};

export default DropdownMyAccount;
