import React, { useEffect, useState } from "react";
import Search from "../../assets/search.svg";
import XmarkSearch from "../../assets/xmark-search.svg";
import Sparkle from "../../assets/sparkle.svg";
// import {useNavigate} from "react-router-dom";
import ToggleComponent from "../ToggleComponent/ToggleComponent";
import "./styles.scss";

const SearchBarComponent = ({
  handleChange,
  setIsFocused,
  handleClose,
  placeholder = "Search...",
  isAiSearchComponent,
  isAiSearch = false,
  setIsAiSearch,
  toggleRef,
  isMagnifier = false,
}) => {
  /*
    Search bar component (input field for searching).
    */

  const [inputValue, setInputValue] = useState("");
  const [focus, setFocus] = useState(false);
  //const navigate = useNavigate();

  useEffect(() => {
    if (focus && inputValue !== "") {
      setIsFocused(true);
    } else if (inputValue === "") {
      setIsFocused(false);
    }
  }, [inputValue, focus]);

  const handleInputChange = (e) => {
    handleChange(e);
    setInputValue(e.target.value);
  };

  const clearInput = () => {
    setInputValue("");
    handleClose();
  };

  // const handleKeyDown = (e) => {
  //     if (e.key === 'Enter') {
  //         navigate(`/search/?query=${encodeURIComponent(inputValue)}`);
  //         handleClose();
  //     }
  // };

  return (
    <div className="search-input-component">
      <div className={`text-input-component-bottom ${focus ? "focus" : ""}`}>
        <div className="icons">
          <img src={isAiSearch ? Sparkle : Search} className="search-icon" />
        </div>
        <input
          type="text"
          onChange={handleInputChange}
          //onKeyDown={handleKeyDown}
          className="text-input-component-input"
          placeholder={placeholder}
          value={inputValue}
          autoFocus={isMagnifier}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
        {isAiSearchComponent && (
          <div className="ai-search-toggle">
            <ToggleComponent
              initialState={false}
              handleToggleSwitch={() => {
                setIsAiSearch(!isAiSearch);
              }}
              size="small"
              isAiSearch={true}
              toggleRef={toggleRef}
            />
          </div>
        )}
        {!isAiSearchComponent && (
          <div className="icons">
            {inputValue && (
              <img
                src={XmarkSearch}
                className="cancel-icon"
                onClick={clearInput}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBarComponent;
