import React, { useEffect, useState } from "react";
import PlaylistComponent from "../PlaylistComponent/PlaylistComponent";
import { useDispatch, useSelector } from "react-redux";
import MinimalViable from "./DraggablePlaylists";
import "./styles.scss";
import {
  setCurrentPlaylist,
  setWatchingPlaylist,
  updatePlaylistName,
  updatePlaylistVisibility,
} from "../../actions/playlist";
import PlaylistBanner from "../VideoList/PlaylistBanner";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import SearchBarComponent from "../SearchBarComponent/SearchBarComponent.js";
import FeedWrapper from "../../containers/FeedWrapper";
import Spinner from "../Spinner/Spinner";

const MyPlaylists = () => {
  /*
    Playlists page accessible via /playlists and /dashboard > My Playlists.
    Displays all playlist, allows to create and edit them, as well as watch videos from playlists.
  */
  const [query, setQuery] = useState("");
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist,
  );

  const items = useSelector((state) => state.playlist.playlists);
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );

  const findItemById = (items, id) => {
    for (const item of items) {
      if (item?.object_id?.slice(0, 8) === id) {
        return item;
      }
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  useEffect(() => {
    if (items !== undefined && items.length > 0) {
      const url = new URL(window.location);
      const playlistIdFromUrl = url.searchParams.get("playlistId");

      if (playlistIdFromUrl?.startsWith("new-")) {
        const playlistName = playlistIdFromUrl.slice(4);
        if (
          playlistName !== items[items.length - 1].title ||
          items[items.length - 1].id.length > 10
        ) {
          dispatch(setWatchingPlaylist("loading"));
          return;
        }
      }

      if (showPlaylistWatch === "watch") {
        if (url.searchParams.has("playlistId"))
          url.searchParams.delete("playlistId");
        window.history.replaceState({}, "", url.toString());
        return;
      }

      if (showPlaylistWatch === "create") {
        if (url.searchParams.has("playlistId"))
          url.searchParams.delete("playlistId");
        window.history.replaceState({}, "", url.toString());
        return;
      }

      if (showPlaylistWatch === false || showPlaylistWatch === "loading") {
        let playlistToShow = null;

        if (playlistIdFromUrl?.startsWith("new-")) {
          playlistToShow = items[items.length - 1];
        } else {
          playlistToShow = findItemById(items, playlistIdFromUrl);
        }

        if (playlistToShow !== null) {
          dispatch(setCurrentPlaylist(playlistToShow));
          dispatch(setWatchingPlaylist("edit"));
        } else {
          dispatch(setWatchingPlaylist(false));
        }
      }

      if (showPlaylistWatch === "edit") {
        url.searchParams.set(
          "playlistId",
          currentPlaylist.object_id.slice(0, 8),
        );
        window.history.replaceState({}, "", url.toString());
      }
    }
  }, [showPlaylistWatch, currentPlaylist, items]);

  const dispatch = useDispatch();

  const filteredItems = items.filter((item) => {
    if (item?.children?.some((i) => i.title?.toLowerCase().includes(query)))
      return true;
    if (
      item?.children?.some((i) =>
        i?.children?.some((ix) => ix.title?.toLowerCase().includes(query)),
      )
    )
      return true;
    return item?.title?.toLowerCase().includes(query);
  });

  const handleCreateNewPlaylist = () => {
    if (showPlaylistWatch !== "create") {
      dispatch(updatePlaylistName(""));
      dispatch(updatePlaylistVisibility("public"));
      dispatch(setWatchingPlaylist("create"));
    }
  };

  return (
    <div className="page-container">
      <div className="playlist-wrapper">
        <div className="left-panel-search-bar">
          <SearchBarComponent
            placeholder={"Search among your playlists"}
            handleChange={(e) => setQuery(e.target.value?.toLowerCase())}
            setIsFocused={() => {}}
            handleClose={() => {
              setQuery("");
            }}
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <ButtonComponent
            text="Create new playlist"
            level="ghost"
            handleClick={handleCreateNewPlaylist}
          />
        </div>
        <MinimalViable items={filteredItems} />
      </div>
      {!showPlaylistWatch ? (
        <div className="video-list-wrapper">
          <PlaylistBanner loading={showPlaylistWatch === "loading"} />
        </div>
      ) : (
        <div className="watch-playlist-wrapper">
          <div className="scrollable-content">
            {showPlaylistWatch === "loading" && <Spinner />}
            {showPlaylistWatch === "watch" && (
              <FeedWrapper type="playlist-tab" isMyTab={true} />
            )}
            {showPlaylistWatch === "edit" && <PlaylistComponent type="edit" />}
            {showPlaylistWatch === "create" && (
              <PlaylistComponent type="create" />
            )}
            {showPlaylistWatch === "watch-video" && (
              <FeedWrapper type="video-tab" isMyTab={true} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPlaylists;
